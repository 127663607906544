import { useState, useEffect, useCallback, useRef } from 'react';

const useWindowResize = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const timeoutId = useRef(null);
	
	const onResize = useCallback(() => {
		clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(() => {
			setWindowWidth(window.innerWidth);
		}, 200);
	}, []);
	
	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
			clearTimeout(timeoutId.current);
		};
	}, [onResize]);

	return windowWidth;
};

export default useWindowResize;

import { useRef, useState, useEffect, Fragment } from 'react';
import UploadService from "../services/FileUploadService";

const Uploader = ({ setUploaderData = null, onUploaded = null, onFileChange = () => { }, multiple = true, path = "/api/upload" }) => {
	const [progressInfos, setProgressInfos] = useState({ val: [] });
	const [imagePreviews, setImagePreviews] = useState([]);
	const [isUploaded, setIsUploaded] = useState(false);
	const [fileList, setFileList] = useState(null);
	const [messages, setMessages] = useState([]);
	const progressInfosRef = useRef({ val: [] });
	const dataTransferRef = useRef(null);
	const inputFileRef = useRef(null);
	const buttonRef = useRef(null);

	const removePreview = (index) => {
		buttonRef.current.classList.add('btn-secondary');
		buttonRef.current.classList.remove('btn-success');
		dataTransferRef.current.items.remove(fileList[index]);
		inputFileRef.current.files = dataTransferRef.current.files;
		setFileList((state) => state.filter((_, key) => index !== key));
		setImagePreviews((state) => state.filter((_, key) => index !== key));
		progressInfosRef.current.val = progressInfosRef.current.val.filter((_, key) => index !== key);
		onUploaded(false);
	};

	const upload = (index, file) => {
		const _progressInfos = [...progressInfosRef.current.val];
		return UploadService.upload(file, path, (event) => {
			_progressInfos[index].percentage = Math.round((100 * event.loaded) / event.total);
			setProgressInfos({ val: _progressInfos });
		}).then((data) => {
			setMessages((state) => [...state, file.name.concat(" uploaded !")]);
			return data;
		}).catch((error) => {
			console.error(error);
		});
	};

	const getFileName = (filename) => {
		const i = filename.lastIndexOf('.');
		const ts = new Date().getTime().toString();
		return (i < 0) ? '' : ts.concat(filename.substr(i));
	};

	const getFileSize = (number) => {
		if (number < 1024) {
			return number + 'bytes';
		} else if (number > 1024 && number < 1048576) {
			return (number / 1024).toFixed(1) + 'KB';
		} else if (number > 1048576) {
			return (number / 1048576).toFixed(1) + 'MB';
		}
	};

	const handleFileChange = (event) => {
		const files = inputFileRef.current.files;
		if (!files.length) return;
		const imagesArray = [];
		const filesArray = [];
		dataTransferRef.current = new DataTransfer();
		Array.from(files).forEach((file) => {
			const fileName = getFileName(file.name);
			const fileSize = getFileSize(file.size);
			const newFile = new File([file], fileName, { type: file.type });
			dataTransferRef.current.items.add(newFile);
			filesArray.push(newFile);
			imagesArray.push({
				url: URL.createObjectURL(newFile),
				fileName: file.name,
				fileSize: fileSize,
			});
			progressInfosRef.current.val.push({
				fileName: file.name,
				percentage: 0,
			});
		});
		inputFileRef.current.files = dataTransferRef.current.files;
		buttonRef.current.classList.remove('btn-secondary');
		buttonRef.current.classList.add('btn-success');
		onFileChange(dataTransferRef.current);
		setImagePreviews(imagesArray);
		setFileList(filesArray);
		setIsUploaded(false);
	};

	const handleUploadClick = () => {
		if (!fileList?.length) return;
		Promise.all(Array.from(fileList).map(async (file, index) => {
			const result = await upload(index, file);
			return result?.data;
		})).then((data) => {
			console.log("Uploader.js handleUploadClick data:", data);
			setUploaderData(data.map((item) => '/upload/' + item));
		}).finally(() => {
			buttonRef.current.classList.remove('btn-success');
			buttonRef.current.classList.add('btn-secondary');
			dataTransferRef.current.items.clear();
			inputFileRef.current.files = dataTransferRef.current.files;
			progressInfosRef.current = { val: [] };
			inputFileRef.current.files = null;
			setProgressInfos({ val: [] });
			setImagePreviews([]);
			setIsUploaded(true);
			setFileList(null);
			setMessages([]);
		});
	};

	useEffect(() => {
		if (!onUploaded) return;
		if (!isUploaded) return;
		onUploaded(isUploaded);
	}, [onUploaded, isUploaded]);

	return (
		<Fragment>
			<div className="input-group">
				<input
					type="file"
					name="files"
					accept="image/x-png,image/gif,image/jpeg"
					className="form-control input-group-sm fs-xs"
					ref={inputFileRef}
					multiple={multiple}
					onChange={handleFileChange}
				/>
				<button
					ref={buttonRef}
					type="button"
					className="btn btn-secondary btn-sm fs-xs"
					onClick={handleUploadClick}>
					Upload
				</button>
			</div>

			{!!imagePreviews.length && (
				<div className="list-group list-group-flush bg-secondary bg-opacity-25 p-2 mt-3 rounded-2">
					{imagePreviews.map((item, index) => (
						<div key={index} className="list-group-item d-flex bg-secondary bg-opacity-10 py-2 border-0 rounded-2 my-1">
							<img src={item.url} alt="twbs" width="45" height="45" className="rounded-circle flex-shrink-0 my-2" />
							<div className="d-flex w-100 justify-content-between align-items-center">
								<div className="px-3">
									<h6 className="mb-0">{item.fileName}</h6>
									<small className="opacity-50 text-nowrap">{item.fileSize}</small>
								</div>
								<span className="opacity-50 text-nowrap">
									<i onClick={() => removePreview(index)} className="bi bi-x-circle-fill fs-5"></i>
								</span>
							</div>
						</div>
					))}
				</div>
			)}

			{progressInfos?.val.length > 0 && (
				<div className="my-3">
					{progressInfos.val.map((progressInfo, index) => (
						<div key={index}>
							<small className="opacity-50">
								{progressInfo.fileName}
							</small>
							<div className="progress">
								<div className="progress-bar progress-bar-info"
									role="progressbar"
									aria-valuenow={progressInfo.percentage}
									aria-valuemin="0" aria-valuemax="100" style={{ width: progressInfo.percentage + "%" }}>
									{progressInfo.percentage}%
								</div>
							</div>
						</div>
					))}
				</div>
			)}

			{!!messages.length && (
				<div className="alert alert-success my-4" role="alert">
					<ul className="list-group list-group-flush">
						{messages.map((item, index) => (
							<li className="list-group-item bg-transparent border-0 p-0" key={index}>
								<p className="text-success m-0">{item}</p>
							</li>
						))}
					</ul>
				</div>
			)}

			{isUploaded && (
				<h2 className="text-success py-2 mt-3">
					<i className="bi bi-check-circle me-2"></i>
					Upload finish !
				</h2>
			)}

		</Fragment>
	);
}

export default Uploader;

import { useState, useEffect, useContext, useCallback, useRef, Fragment, useMemo } from "react";
import { Link, useLocation } from 'react-router-dom';
import WebPushService from '../services/WebPushService';
import { alertService } from '../services/AlertService';
import HttpService, { getURL } from "../http-common";
import { Collapse, Modal, Alert } from "bootstrap";
import UserService from "../services/UserService";
import useLocalStorage from '../useLocalStorage';
import { Image } from "react-bootstrap";
import { Context } from "../Context";
import UserInfo from "./UserInfo";
import Uploader from "./Uploader";
import Document from "./Document";
import axios from "axios";

const User = () => {
	const { context, setContext, updateContext, setIsLoading } = useContext(Context);
	const [notificationsRef, setNotificationsRef] = useState(null);
	const [setLocalStorage, getLocalStorage] = useLocalStorage();
	const [isPushEnabled, setIsPushEnabled] = useState(false);
	const [isAlertClose, setIsAlertClose] = useState(false);
	const [uploaderData, setUploaderData] = useState(null);
	const [showConfirm, setShowConfirm] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [btnCloseRef, setBtnCloseRef] = useState(null);
	const [isUploaded, setIsUploaded] = useState(false);
	const [currAvatar, setCurrAvatar] = useState(null);
	const [messageRef, setMessageRef] = useState(null);
	const [contentId, setContentId] = useState(null);
	const [alertType, setAlertType] = useState(null);
	const [bannerId, setBannerId] = useState(null);
	const [fileList, setFileList] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [message, setMessage] = useState(null);
	const showNotificationsAlert = useRef(true);
	const [avatar, setAvatar] = useState(null);
	const notificationsAlertRef = useRef(null);
	const [data, setData] = useState(null);
	const [user, setUser] = useState(null);
	const modalAvatarRef = useRef(null);
	const formElement = useRef(null);
	const modalRef = useRef(null);
	const location = useLocation();

	showNotificationsAlert.current = useMemo(() => {
		const isAlertShow = getLocalStorage("showNotificationsAlert");
		return (isAlertShow === null) ? setLocalStorage("showNotificationsAlert", true) : isAlertShow;
	}, [getLocalStorage, setLocalStorage]);

	const handleSubmit = useCallback(async (event) => {
		event.preventDefault();
		try {
			if (fileList && isSubmitted) {
				alertService.warn("upload images before send!", { autoClose: true });
				setIsSubmitted(true);
			} else {
				const form = formElement.current;
				const data = Array.from(form.elements)
					.filter((input) => input.name)
					.reduce((obj, input) => {
						return Object.assign(obj, { [input.name]: input.value })
					}, {});
				if (!!Object.keys(data).length) {
					const parts = avatar.split('/');
					data.avatar = avatar !== "/defaultUser.png" ? parts[parts.length - 1] : "";
					data.files = data.avatar;
					const result = await HttpService.fetchData({
						url: getURL.concat("/api/user/edit/" + user.id),
						body: JSON.stringify(data),
						method: "post",
					});
					if (result) {
						setIsSubmitted(true);
						if (result?.data) {
							console.log("result.data:", result.data);
							setContext((state) => ({ ...state, user: result.data }));
							setAvatar(result.data.avatar);
							setUser(result.data);
						}
						if (result?.message) {
							alertService.success(result.message);
							console.log(result.message);
						}
					}
				}
			}
		} catch (error) {
			alertService.warn(error.message);
			console.error(error.message);
		}
	}, [user, avatar, fileList, isSubmitted, setContext]);

	const getData = useCallback(async () => {
		try {
			const results = {};
			setLoading(false);
			setIsLoading(false);
			if (!user) return;
			results.notifications = await UserService.getUserNotifications(user.id);
			results.contents = await UserService.getContents();
			results.banners = await UserService.getBanners();
			setData(results);
		} catch (error) {
			console.error(error.message);
		}
	}, [user, setIsLoading]);

	const onCollapse = useCallback((event) => {
		const headerText = event.currentTarget.childNodes[0];
		const arrow = headerText.childNodes[0];
		if (event.currentTarget.ariaExpanded === "true") {
			arrow.classList.remove("bi-chevron-down");
			arrow.classList.add("bi-chevron-up");
		} else {
			arrow.classList.remove("bi-chevron-up");
			arrow.classList.add("bi-chevron-down");
		}
	}, []);

	const getBackgroundColor = useCallback((type) => {
		switch (type) {
			case "content-create": return "bg-success bg-opacity-75 border-0";
			case "content-update": return "bg-warning bg-opacity-75 border-0";
			case "content-delete": return "bg-danger bg-opacity-75 border-0";
			default: return "bg-info bg-opacity-75 border-0";
		}
	}, []);

	const getTime = useCallback((data) => {
		const options = {
			hour: "numeric",
			minute: "numeric",
			second: "numeric"
		};
		return new Intl.DateTimeFormat("es-UY", options).format(new Date(data));
	}, []);

	const getDate = useCallback((data) => {
		return new Date(data).toLocaleDateString("es-UY");
	}, []);

	const handleClickScroll = useCallback(async () => {
		if (messageRef) messageRef.scrollIntoView(false);
	}, [messageRef]);

	const subscribe = useCallback(async () => {
		try {
			setLoading(true);
			const subscribe = await WebPushService.subscribe();
			console.log("subscribe:", subscribe);
			if (subscribe) {
				console.log("subscribe:", subscribe);
				setIsPushEnabled(true);
				setLoading(false);
			}
		} catch (error) {
			console.error(error.message);
			alertService.error(error.message);
			setLoading(false);
		}
	}, []);

	const unsubscribe = useCallback(async () => {
		try {
			setLoading(true);
			const subscription = await WebPushService.unsubscribe();
			if (subscription) {
				const endpoint = subscription.endpoint;
				const result = await axios.delete(getURL.concat("/api/user/subscription"), {
					data: { endpoint }
				});
				if (result?.data) {
					console.log("unsubscribe:", result.data.message);
					setIsPushEnabled(false);
					setLoading(false);
				}
			}
		} catch (error) {
			console.error(error.message);
			alertService.error(error.message);
			setLoading(false);
		}
	}, []);

	const onButtonClick = useCallback(async () => {
		if (isPushEnabled) {
			unsubscribe();
			setLocalStorage('showNotificationsAlert', true);
			setIsAlertClose(false);
		} else {
			setLocalStorage('showNotificationsAlert', false);
			const result = await WebPushService.requestPermission();
			if (result === "granted") {
				console.log('requestPermission result:', result);
				subscribe();
			}
		}
	}, [isPushEnabled, subscribe, unsubscribe, setLocalStorage]);

	const onAlertClose = useCallback(() => {
		if (!notificationsAlertRef?.current) return;
		setLocalStorage('showNotificationsAlert', false);
		setIsAlertClose(true);
	}, [setLocalStorage]);

	const deleteUserNotifications = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/notifications/user/" + user.id + "/all"),
				method: "delete",
			});
			if (!!result.data.length && messageRef) {
				console.log(result.data);
				messageRef.classList.add('alert-danger');
				setMessage('Deleted !');
				setTimeout(() => {
					setMessage(null);
				}, 2000);
			}
			if (result.message) {
				console.log(result.message);
			}
			setContext((state) => ({
				...state,
				user: {
					...state.user,
					notifications: [],
				}
			}));
		} catch (error) {
			console.error(error.message);
		}
	}, [user, messageRef, setContext]);

	const deleteContent = useCallback(async (id) => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/contents/" + String(id)),
				method: "delete",
			});
			if (result.data && messageRef) {
				console.log("result.data && messageRef:", result.data)
				updateContext();
				getData();
			}
			if (result.message && messageRef) {
				console.log("result.message && messageRef:", result.message)
				messageRef.classList.add('alert-danger');
				btnCloseRef.classList.add('fade');
				messageRef.classList.add('fade');
				setMessage(result.message);
				setTimeout(() => {
					messageRef.classList.remove('show');
					setTimeout(() => {
						setMessage(null);
					}, 200);
				}, 2000);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [updateContext, getData, messageRef, btnCloseRef]);

	const deleteBanner = useCallback(async (id) => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/banners/" + String(id)),
				method: "delete",
			});
			if (result.data && messageRef) {
				console.log("result.data && messageRef:", result.data)
				updateContext();
				getData();
			}
			if (result.message && messageRef) {
				console.log("result.message && messageRef:", result.message)
				messageRef.classList.add('alert-danger');
				btnCloseRef.classList.add('fade');
				messageRef.classList.add('fade');
				setMessage(result.message);
				setTimeout(() => {
					messageRef.classList.remove('show');
					setTimeout(() => {
						setMessage(null);
					}, 200);
				}, 2000);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [updateContext, getData, messageRef, btnCloseRef]);

	const onMessageClick = useCallback(() => {
		setShowConfirm(false);
		if (alertType === 'contents') {
			deleteContent(contentId);
		} else if (alertType === 'banners') {
			deleteBanner(bannerId);
		} else if (alertType === 'notifications') {
			deleteUserNotifications();
		}
	}, [deleteContent, deleteBanner, deleteUserNotifications, contentId, bannerId, alertType]);

	const onDeleteNotifications = useCallback(async () => {
		try {
			setMessage('All notifications well be deleted');
			setAlertType('notifications');
			setShowConfirm(true);
			if (messageRef) {
				messageRef.classList.remove("d-none");
				handleClickScroll();
				setTimeout(() => {
					messageRef.classList.add("show");
				}, 200);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [handleClickScroll, messageRef]);

	const onDeleteContent = useCallback(async ({ id, name }) => {
		try {
			setMessage('El content ' + name + ' será eliminado');
			setAlertType('contents');
			setShowConfirm(true);
			setContentId(id);
			if (messageRef) {
				messageRef.classList.remove("d-none");
				handleClickScroll();
				setTimeout(() => {
					messageRef.classList.add("show");
				}, 200);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [handleClickScroll, messageRef]);

	const onDeleteBanner = useCallback(async ({ id, name }) => {
		try {
			setMessage('El banner ' + name + ' será eliminado');
			setAlertType('banners');
			setShowConfirm(true);
			setBannerId(id);
			if (messageRef) {
				messageRef.classList.remove("d-none");
				handleClickScroll();
				setTimeout(() => {
					messageRef.classList.add("show");
				}, 200);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [handleClickScroll, messageRef]);

	const onClose = useCallback((event) => {
		event.target.parentElement.classList.remove("show");
		setTimeout(() => {
			event.target.parentElement.classList.add("d-none");
		}, 200);
	}, []);

	const notificationView = useCallback(async (item) => {
		console.log("item:", item)
		try {
			const { id, viewed } = item;
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/notifications/" + id),
				body: { viewed: !viewed },
				method: "post",
			});
			if (result?.data) {
				setContext((state) => ({
					...state,
					user: {
						...state.user,
						notifications: state.user.notifications.map((item) => {
							return (id === item.id) ? result.data : item;
						})
					}
				}));
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [setContext]);

	const notificationsViewAll = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/notifications/viewed"),
				method: "post",
				body: {
					userId: user.id,
					viewed: true,
				},
			});
			if (result?.data) {
				setContext((state) => ({
					...state,
					user: {
						...state.user,
						notifications: result.data,
					}
				}));
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [setContext, user]);

	const isSubscription = useCallback(async (user, data) => {
		const subscriptions = await UserService.getUserSubscriptions(user.id);
		const result = subscriptions.some(({ endpoint }) => endpoint === data.endpoint);
		return result;
	}, []);

	useEffect(() => {
		setIsLoading(true);
		getData();
	}, [getData, setIsLoading]);

	useEffect(() => {
		if (!uploaderData?.length) return;
		console.log("User.js uploaderData:", uploaderData)
		setCurrAvatar(uploaderData[0]);
		setAvatar(uploaderData[0]);
	}, [uploaderData]);

	useEffect(() => {
		if (!isUploaded) return;
		alertService.clear();
	}, [isUploaded]);

	useEffect(() => {
		if (!user) return;
		WebPushService.getSubscription().then(async (subscription) => {
			setLoading(false);
			if (subscription) {
				const checkSubscription = await isSubscription(user, subscription);
				if (checkSubscription) {
					setIsPushEnabled(true);
				} else {
					setIsPushEnabled(false);
				}
			}
		}).catch((error) => {
			console.error('subscription error: ', error);
		});
	}, [user, isSubscription]);

	useEffect(() => {
		if (!context?.user) return;
		setUser(context.user);
		if (context?.user?.avatar) {
			setCurrAvatar(context.user.avatar);
			setAvatar(context.user.avatar);
		}
	}, [context]);

	useEffect(() => {
		if (messageRef) {
			messageRef.classList.remove("d-none");
			handleClickScroll();
			setTimeout(() => {
				messageRef.classList.add("show");
			}, 200)
		}
	}, [messageRef, handleClickScroll]);

	useEffect(() => {
		if (location?.state?.scrollTo && data?.notifications && notificationsRef) {
			const collapse = Collapse.getOrCreateInstance(notificationsRef);
			collapse.show();
			collapse._element.addEventListener('shown.bs.collapse', (event) => {
				const element = event.target.parentElement;
				const elementPosition = element.getBoundingClientRect().top;
				const offsetPosition = elementPosition + window.scrollY - 70;
				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth',
				});
			});
		}
	}, [data, location, notificationsRef]);

	useEffect(() => {
		if (!modalRef?.current) return;
		const modal = Modal.getOrCreateInstance(modalRef.current);
		if (!isSubmitted) return;
		setIsSubmitted(false);
		modal.hide();
	}, [isSubmitted, isUploaded]);

	useEffect(() => {
		if (!isAlertClose) return;
		if (!notificationsAlertRef?.current) return;
		const bsAlert = Alert.getOrCreateInstance(notificationsAlertRef.current);
		notificationsAlertRef.current.classList.remove("show");
		setIsAlertClose(false);
		setTimeout(() => {
			bsAlert.close();
		}, 200);
	}, [isAlertClose]);

	return (
		<Document title={(user?.username || "") + " | saincor"} className="page-user">
			<div className="container">
				{message && (
					<div ref={setMessageRef} className="alert alert-warning alert-dismible fade d-flex align-items-center justify-content-between p-2 my-4">
						<div className="justify-content-start px-2">
							<span className="me-2 align-middle fs-xs">{message}</span>
							{showConfirm && (
								<button className="btn btn-link text-danger text-decoration-none p-0 m-0 align-middle fw-bold" onClick={onMessageClick}>
									Yes?
								</button>
							)}
						</div>
						<button ref={setBtnCloseRef} onClick={onClose} type="button" className="btn-close fs-xs px-2" aria-label="Close"></button>
					</div>
				)}
				{user && (
					<main>
						<div className="row py-5 pb-4 text-center">
							<div className="user-img mb-4 position-relative mx-auto">
								<div className="user-img-container">
									<button type="button" className="btn btn-link p-0 m-0 border-0" data-bs-toggle="modal" data-bs-target="#modalAvatar">
										<Image style={{ maxWidth: 160 }} src={getURL.concat(user.avatar)} alt="user-avatar" roundedCircle />
									</button>
								</div>
							</div>
							<h2 className="d-flex justify-content-center align-items-center">
								<div className="d-flex align-items-center text-lowercase mb-2">
									<span className="overflow-hidden fs-4 text-truncate" style={{ maxWidth: 150 }}>
										{user.username}
									</span>
									<button type="button" className="align-middle btn btn-link p-0 m-0 ms-2 fs-5 border-0" data-bs-toggle="modal" data-bs-target="#modal">
										<i className="bi bi-sliders"></i>
									</button>
								</div>
							</h2>

							{(showNotificationsAlert.current && !isPushEnabled) && (
								<div className="d-flex justify-content-center position-relative" style={{ marginBottom: 20 }}>
									<div ref={notificationsAlertRef} className="alert alert-info fade show p-1 m-0 shadow d-flex align-items-center border-0" role="alert">
										<p className="m-0 mx-2 fs-xs" style={{ color: "var(--#{$prefix}alert-color)" }}>
											Mantenete actualizado activando las notificaciones
										</p>
										<button onClick={onAlertClose} type="button" className="btn btn-link p-0 ms-2 text-decoration-none">
											<i className="bi bi-x fw-bolder d-flex fs-4" />
										</button>
										<div className="position-absolute bottom-0 start-50 translate-middle-x">
											<i className="caret d-block" />
										</div>
									</div>
								</div>
							)}
							<div className="m-auto mb-2">
								<button onClick={onButtonClick} className={"btn btn-sm px-3 text-white bg-opacity-75 bg-" + (isLoading ? "dark-subtle" : (isPushEnabled ? "gray-400" : "primary"))} type='button'>
									{isLoading ? (
										<Fragment>
											<span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
											<span role="status">Loading...</span>
										</Fragment>
									) : (
										<Fragment>
											<i className={"me-2 bi bi-bell" + (isPushEnabled ? "-slash-fill" : "-fill")} />
											<span className="align-self-center">{isPushEnabled ? "desactivar notificaciones" : "activar notificaciones"}</span>
										</Fragment>
									)}
								</button>
							</div>

							<div ref={modalAvatarRef} className="modal fade text-start" id="modalAvatar" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
								<div className="modal-dialog modal-md modal-dialog-centered">
									<div className="modal-content bg-dark overflow-hidden">
										<img className="img-fluid" src={getURL.concat(avatar)} alt="modal-avatar" />
									</div>
								</div>
							</div>

							<div ref={modalRef} className="modal fade text-start" id="modal" tabIndex="-1" data-bs-backdrop="static" aria-labelledby="modalLabel" aria-hidden="true">
								<div className="modal-dialog modal-md modal-dialog-centered">
									<div className="modal-content">
										<form ref={formElement} onSubmit={handleSubmit}>
											<div className="modal-header border-secondary border-opacity-50 bg-dark-secondary bg-opacity-25">
												<button type="button" className="fs-xs btn-close" data-bs-dismiss="modal" aria-label="Close" />
											</div>
											<div className="modal-body">
												<div className="mb-2">
													<label htmlFor="inputUsername" className="form-label fs-xs">Username</label>
													<input name="username" type="text" className="form-control input-group-sm fs-xs" id="inputUsername" aria-describedby="userHelp" autoComplete="off" defaultValue={user.username} />
												</div>
												<div className="mb-2">
													<label htmlFor="inputEmail1" className="form-label fs-xs">Email address</label>
													<input name="email" type="email" className="form-control input-group-sm fs-xs" id="inputEmail1" aria-describedby="emailHelp" autoComplete="off" defaultValue={user.email} />
												</div>
												<div className="mb-2">
													<label htmlFor="inputPassword" className="form-label fs-xs">Password</label>
													<input placeholder={String("*").repeat(10)} name="password" type="text" className="form-control input-group-sm fs-xs" id="inputPassword" autoComplete="off" />
												</div>
												<div className="mb-2">
													<label className="form-label">Images</label>
													<div className="mb-2 ">
														<Uploader
															setUploaderData={setUploaderData}
															onFileChange={setFileList}
															onUploaded={setIsUploaded}
															multiple={false}
														/>
													</div>
													<div className="row row-cols-4 g-2 my-2 g-1">
														<div className="col-3 overflow-hidden">
															<img className="preview img-fluid" src={getURL.concat(currAvatar)} alt="avatar" />
														</div>
													</div>
												</div>
											</div>
											<div className="modal-footer border-secondary border-opacity-75">
												<button type="button" className="btn btn-sm btn-secondary fs-xs" data-bs-dismiss="modal">Cancelar</button>
												<button type="submit" className="btn btn-sm btn-success fs-xs">Guardar</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						{data && (
							<div className="row">
								<div className="col-md-6 order-2 order-md-1">
									<UserInfo user={user} />
								</div>
								<div className="col-md-6 order-1 order-md-2">
									{(user?.role === "admin") && (
										<Fragment>
											<div className="card my-4 shadow-sm" data-bs-theme="light">
												<div className="card-header d-flex justify-content-between align-items-center border-primary border-opacity-25 p-3 py-2">
													<h4
														role="button"
														onClick={onCollapse}
														aria-controls="collapse"
														data-bs-toggle="collapse"
														data-bs-target="#collapseContents"
														className="h6 d-flex flex-wrap justify-content-between align-items-center m-0 w-100">
														<span className="text-body">
															<i className="bi bi-chevron-down me-2"></i>
															Contents
															<span className="ms-2 badge bg-primary rounded-pill fs-xs">
																{data?.contents && data.contents.length}
															</span>
														</span>
													</h4>
													<div className="btn-group align-items-center">
														<Link className="btn btn-sm btn-success opacity-75 fs-xs lh-md" to="/content/add">Agregar</Link>
													</div>
												</div>
												<div className="collapse show" id="collapseContents">
													<div className="card-body shadow-in">
														<ul className="list-group list-group-flush">
															{data?.contents && data.contents.map((item, index) => {
																// console.log('contents item', item)
																return (
																	<li key={index} className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-secondary border-opacity-25">
																		<div className="my-2 w-75 overflow-hidden">
																			<Link className="fs-xs w-100 text-decoration-none d-block text-nowrap" to={'/content/edit/' + item.id}>
																				<img style={{ width: 40 }} className="img-fluid me-3" src={getURL.concat(JSON.parse(item.image)[0])} alt="team" />
																				{item.name}
																			</Link>
																		</div>
																		<div style={{ width: 20 }} className="text-end position-relative">
																			<button className="btn btn-link text-danger fs-6 p-0 mx-2" onClick={() => onDeleteContent(item)}>
																				<i className="bi bi-trash"></i>
																			</button>
																		</div>

																	</li>
																)
															})}
														</ul>
													</div>
												</div>
											</div>

											<div className="card my-4 shadow-sm" data-bs-theme="light">
												<div className="card-header d-flex justify-content-between align-items-center border-primary border-opacity-25 p-3 py-2">
													<h4
														role="button"
														onClick={onCollapse}
														aria-controls="collapse"
														data-bs-toggle="collapse"
														data-bs-target="#collapseBanners"
														className="h6 d-flex flex-wrap justify-content-between align-items-center m-0 w-100">
														<span className="text-body">
															<i className="bi bi-chevron-down me-2"></i>
															Banners
															<span className="ms-2 badge bg-primary rounded-pill fs-xs">
																{data?.banners && data.banners.length}
															</span>
														</span>
													</h4>
													<div className="btn-group align-items-center">
														<Link className="btn btn-sm btn-success opacity-75 fs-xs lh-md" to="/banner/add">Agregar</Link>
													</div>
												</div>
												<div className="collapse" id="collapseBanners">
													<div className="card-body shadow-in">
														<ul className="list-group list-group-flush">
															{data?.banners && data.banners.map((item, index) => {
																// console.log('contents item', item)
																return (
																	<li key={index} className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-secondary border-opacity-25">
																		<div className="my-2 w-75 overflow-hidden">
																			<Link className="fs-xs w-100 text-decoration-none d-block text-nowrap" to={'/banner/edit/' + item.id}>
																				<img style={{ width: 40 }} className="img-fluid me-3" src={getURL.concat(JSON.parse(item.image)[0])} alt="team" />
																				{item.name}
																			</Link>
																		</div>
																		<div style={{ width: 20 }} className="text-end position-relative">
																			<button className="btn btn-link text-danger fs-6 p-0 mx-2" onClick={() => onDeleteBanner(item)}>
																				<i className="bi bi-trash"></i>
																			</button>
																		</div>

																	</li>
																)
															})}
														</ul>
													</div>
												</div>
											</div>
										</Fragment>
									)}
									<div className="card my-4 shadow-sm">
										<div className="card-header d-flex justify-content-between align-items-center border-primary border-opacity-25 p-3 py-2">
											<h4
												role="button"
												onClick={onCollapse}
												aria-controls="collapse"
												data-bs-toggle="collapse"
												data-bs-target="#collapseNotifications"
												className="h6 d-flex align-items-center m-0 w-100">
												<span className="text-body w-100">
													<i className="bi bi-chevron-down me-2"></i>
													Notificaciones
													<span className="ms-2 badge bg-primary rounded-pill fs-xs">
														{data?.notifications && data.notifications.length}
													</span>
												</span>
											</h4>
											<div className="btn-group">
												<button className="btn btn-link text-success p-0" onClick={notificationsViewAll}>
													<i className="bi bi-eye"></i>
												</button>
												<button className="btn btn-link text-danger p-0 ms-4" onClick={onDeleteNotifications}>
													<i className="bi bi-trash"></i>
												</button>
											</div>
										</div>
										<div className="collapse" id="collapseNotifications" ref={setNotificationsRef}>
											<div className="card-body shadow-in">
												<ul className="list-group list-group-flush">
													{data?.notifications && data.notifications.map((item, index) => {
														// console.log("item:", item)
														return (
															<li key={index} className="list-group-item p-0 border-0 mb-3">
																<div className="card flex-fill rounded-0 border-0">
																	<div className="card-header bg-transparent py-2 d-flex align-items-center">
																		<span className={"d-inline-block me-2 rounded-circle " + getBackgroundColor(item.type)} style={{ height: 12, width: 12 }} />
																		{item.type.split('-').join(" ")}
																	</div>
																	<div className="card-body d-flex justify-content-between">
																		<div className="flex-fill">
																			<p className="mb-1 fs-xs">{item.text}</p>
																			<p className="opacity-50 m-0">{item.data.itemName}</p>
																		</div>
																		<button className="btn btn-link text-body border-0 p-0" onClick={() => notificationView(item)}>
																			<i className={"bi bi-eye " + (item.viewed && "text-success")}></i>
																		</button>
																	</div>
																	<div className="card-footer opacity-75 d-flex">
																		<span style={{ fontSize: 10 }}>
																			<i className="bi bi-calendar me-1" />
																			{getDate(item.createdAt)}
																		</span>
																		<span style={{ fontSize: 10, marginLeft: 20 }}>
																			<i className="bi bi-clock me-1" />
																			{getTime(item.createdAt)}
																		</span>
																	</div>
																</div>


															</li>
														)
													})}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</main>
				)}

			</div>
		</Document>
	)
}

export default User

import { useEffect, useRef, Fragment } from "react";

const UserInfo = ({ user }) => {
	const userInfoRef = useRef(null);

	const getTime = (data) => {
		const options = {
			hour: "numeric",
			minute: "numeric",
			second: "numeric"
		};
		return new Intl.DateTimeFormat("es-UY", options).format(new Date(data));
	};

	const getDate = (data) => {
		return new Date(data).toLocaleDateString("es-UY");
	};

	useEffect(() => {
		if (window.innerWidth <= 768) {
			userInfoRef.current.classList.remove("show");
		}
	}, []);

	return (
		<Fragment>
			<h4 className="pb-2 text-primary border-bottom border-primary text-opacity-75 border-opacity-75 mb-4">
				<a className="d-flex justify-content-between align-items-center text-decoration-none" href="$collapseUserInfo" role="button" data-bs-toggle="collapse" data-bs-target="#collapseUserInfo" aria-expanded="false" aria-controls="collapseUserInfo">
					<span className="me-auto w-100">
						User info
					</span>
					<i className="bi bi-list"></i>
				</a>
			</h4>
			<div ref={userInfoRef} className="collapse show mb-4" id="collapseUserInfo">
				<div>
					<div className="row g-3">
						<div className="col-12">
							<label htmlFor="username" className="form-label">Usuario</label>
							<div className="input-group">
								<span className="input-group-text text-dark">
									<i className="bi bi-person"></i>
								</span>
								<input type="text" className="form-control input-group-sm" id="username" defaultValue={user.username} placeholder="Username" disabled />
							</div>
						</div>
						<div className="col-12">
							<label htmlFor="email" className="form-label">Email</label>
							<div className="input-group">
								<span className="input-group-text text-dark">@</span>
								<input type="email" className="form-control input-group-sm" id="email" placeholder={user.email} disabled />
							</div>
						</div>
						<div className="col-12">
							<label className="form-label">Creado</label>
							<div className="input-group">
								<div className="row g-3">
									<div className="col-6">
										<div className="input-group">
											<span className="input-group-text text-dark">
												<i className="bi bi-calendar"></i>
											</span>
											<input type="text" className="form-control input-group-sm" id="createdAt" defaultValue={getDate(user.createdAt)} placeholder="createdAt" disabled />
										</div>
									</div>
									<div className="col-6">
										<div className="input-group">
											<span className="input-group-text">
												<i className="bi bi-clock"></i>
											</span>
											<input type="text" className="form-control input-group-sm" id="createdAtTime" defaultValue={getTime(user.createdAt)} placeholder="createdAtTime" disabled />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default UserInfo;

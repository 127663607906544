import HttpService, { getURL } from "../http-common";
// import WebPushService from './WebPushService';

const getUserItems = async (id) => {
	try {
		if (!id) return;
		const result = await HttpService.fetchData({
			url: "/api/items/user/" + String(id),
		});
		if (result?.data) {
			return result.data;
		}
	} catch (error) {
		console.error(error.message);
	}
};

const getUserFavorites = async (id) => {
	try {
		if (!id) return;
		const result = await HttpService.fetchData({
			url: getURL.concat("/api/favorites/user/" + String(id)),
		});
		if (result?.data) {
			return result.data;
		}
	} catch (error) {
		console.error(error.message);
	}
};

const getUserOffers = async (id) => {
	try {
		if (!id) return;
		const result = await HttpService.fetchData({
			url: "/api/offers/offer/" + String(id),
		});
		if (result?.data) {
			return result.data.reverse();
		}
	} catch (error) {
		console.error(error.message);
	}
};

const getUserNotifications = async (id) => {
	try {
		if (!id) return;
		const result = await HttpService.fetchData({
			url: "/api/notifications/user/" + String(id) + "/all",
		});
		if (result?.data) {
			return result.data;
		}
		if (result.message) {
			console.log(result.message);
		}
	} catch (error) {
		console.error(error.message);
	}
};

const getUserCreator = async (id) => {
	try {
		if (!id) return;
		const resultItem = await HttpService.fetchData({
			url: getURL.concat("/api/item/" + id)
		});
		if (resultItem?.data) {
			const creatorId = resultItem.data.creatorId;
			const resultUser = await HttpService.fetchData({
				url: getURL.concat("/api/user/" + creatorId),
			});
			if (resultUser.data) {
				return resultUser.data;
			}
		} else return null;
	} catch (error) {
		console.error(error.message);
	}
};

const getUserSubscriptions = async (id) => {
	try {
		if (!id) return;
		const result = await HttpService.fetchData({
			url: getURL.concat("/api/user/subscriptions/" + id)
		});
		// console.log("getUserSubscriptions result:", result.data);
		if (result?.data) {
			return result.data;
		} else return [];
	} catch (error) {
		console.error(error.message);
	}
};

const getTeams = async () => {
	try {
		const result = await HttpService.fetchData({
			url: getURL.concat("/api/teams/")
		});
		if (result?.data) {
			return result.data;
		} else return [];
	} catch (error) {
		console.error(error.message);
	}
};

const getContents = async () => {
	try {
		const result = await HttpService.fetchData({
			url: getURL.concat("/api/contents/")
		});
		if (result?.data) {
			return result.data;
		} else return [];
	} catch (error) {
		console.error(error.message);
	}
};

const getBanners = async () => {
	try {
		const result = await HttpService.fetchData({
			url: getURL.concat("/api/banners/")
		});
		if (result?.data) {
			return result.data;
		} else return [];
	} catch (error) {
		console.error(error.message);
	}
};

const getMatches = async () => {
	try {
		const result = await HttpService.fetchData({
			url: getURL.concat("/api/matches/")
		});
		if (result?.data) {
			return result.data;
		} else return [];
	} catch (error) {
		console.error(error.message);
	}
};

const getUserCredits = async (uid) => {
	try {
		const result = await HttpService.fetchData({
			url: getURL.concat("/api/credits/").concat(uid)
		});
		if (result?.data) {
			return result.data;
		} else return [];
	} catch (error) {
		console.error(error.message);
	}
};

const getUserDeliveries = async (uid, role) => {
	try {
		var query = "/?";
		query += "&role=" + role;
		const result = await HttpService.fetchData({
			url: getURL.concat("/api/delivery/user/").concat(uid).concat(query)
		});
		if (result?.data) {
			return result.data;
		} else return [];
	} catch (error) {
		console.error(error.message);
	}
};

// const getUser = async () => {
// 	try {
// 		const getAuthToken = await localStorage.getItem('token');
// 		if (getAuthToken) {
// 			const result = await HttpService.fetchData({
// 				body: { token: getAuthToken },
// 				url: getURL.concat("/api/auth"),
// 				method: "post",
// 			});
// 			if (result?.data) {
// 				console.color.success('UserService user:', result.data, false)
// 				return result.data;
// 			}
// 		} else return null;
// 	} catch (error) {
// 		console.error(error.message);
// 	}
// };

const getUser = async () => {
	try {
		const result = await HttpService.fetchData({
			url: getURL.concat("/api/auth"),
		});
		if (result?.data) {
			console.color.success('UserService user:', result.data, false);
			return result;
		} else return null;
	} catch (error) {
		console.error(error.message);
	}
};

const UserService = {
	getUserNotifications,
	getUserSubscriptions,
	getUserDeliveries,
	getUserFavorites,
	getUserCreator,
	getUserCredits,
	getUserOffers,
	getUserItems,
	getContents,
	getBanners,
	getMatches,
	getTeams,
	getUser
};

export default UserService;

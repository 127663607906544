import { useState, useEffect, useCallback, useRef, useContext } from "react";
import HttpService, { getURL } from "../http-common";
import { DisplaySpinner } from "./DisplaySpinner";
import useWindowResize from '../useWindowResize';
import imageLoader from "../imageLoader";
import { Link } from 'react-router-dom';
import animateCSS from "../animateCSS";
import { Context } from "../Context";
import Document from "./Document";

const debug = false;

const Users = () => {
	const windowWidth = useWindowResize();
	const { context } = useContext(Context);
	const initPaginate = (windowWidth >= 768) ? 12 : 6;
	// const [paginate, setPaginate] = useState(initPaginate);
	const [autoscroll, setAutoscroll] = useState(false);
	// const [buttonRef, setButtonRef] = useState(null);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState(null);
	const paginateRef = useRef(initPaginate);
	const [data, setData] = useState(null);
	const animatedIndexesRef = useRef({});
	// const initialized = useRef(false);
	const inputSearchRef = useRef(null);
	const totalCountRef = useRef(null);
	const buttonRef = useRef(null);
	const usersRef = useRef([]);
	const delayRef = useRef(0);
	const total = useRef(0);

	const scrollToBottom = useCallback(() => {
		if (!context?.bottomRef) return;
		context.bottomRef.scrollIntoView({ behavior: "smooth" });
	}, [context]);

	const getData = useCallback(async () => {
		try {
			var query = "/?";
			if (paginateRef.current) query += "&limit=" + paginateRef.current;
			if (search) query += "&data=" + search;
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/users").concat(query)
			});
			if (result?.data) {
				if (search) {
					console.color.danger("getData result.data is search:", result.data, debug);
					animatedIndexesRef.current = {};
					setData(result.data.filter((item) => {
						const searchTerm = search.toLowerCase();
						const name = item.username.toLowerCase();
						return name.includes(searchTerm);
					}));
				} else {
					console.color.danger("getData result.data no search:", result.data, debug);
					setData(result.data);
				}
			}
			if (result?.total && !search) {
				total.current = result.total;
			}
		} catch (error) {
			console.error(error.message);
			setLoading(false);
		}
	}, [search]);

	const loadMore = useCallback(() => {
		paginateRef.current += initPaginate;
		inputSearchRef.current.value = null;
		getData(paginateRef.current);
		setAutoscroll(true);
		setLoading(true);
		setSearch(null);
	}, [getData, initPaginate]);

	const onChange = useCallback((event) => {
		const value = event.target.value;
		// animatedIndexesRef.current = {};
		setSearch(value.toLowerCase());
		if (value) setAutoscroll(false);
	}, []);

	useEffect(() => {
		if (!data?.length) return;
		if (!totalCountRef?.current) return;
		totalCountRef.current.innerHTML = (search) ? data.length : total.current;
	}, [data, search]);

	useEffect(() => {
		if (!data?.length) return;
		if (!buttonRef?.current) return;
		const button = buttonRef.current;
		if ((total.current === data.length) && !search) {
			button.classList.add('fade');
			const transitionend = () => {
				setTimeout(() => (button.style.display = "none"), 600);
			};
			button.addEventListener('transitionend', transitionend);
			return () => {
				button.removeEventListener('transitionend', transitionend);
			}
		}
	}, [data, search]);

	useEffect(() => {
		console.color.info("useEffect getData:", debug);
		getData();
	}, [getData]);

	useEffect(() => {
		if (!data?.length) return;
		console.color.warn("useEffect data is no null:", data, debug);
		const srcArray = data.map((user, index) => {
			if (index === 0) setLoading(true);
			return getURL.concat(user.avatar)
		});
		imageLoader(srcArray, () => {
			setLoading(false);
		});
	}, [data]);

	useEffect(() => {
		if (loading) return;
		if (!data?.length) return;
		if (!usersRef?.current.length) return;
		const usersArray = usersRef.current.slice(0, data.length);
		const usersArrayLength = usersArray.length;
		usersArray.forEach((element, index) => {
			if (animatedIndexesRef.current[index] === true) return;
			animatedIndexesRef.current[index] = true;
			setTimeout(() => {
				// if (!element) return;
				element.style.display = "block";
				animateCSS({ element, animation: 'bounceIn' });
				if ((index + 1) === usersArrayLength) {
					if (autoscroll) scrollToBottom();
				}
			}, delayRef.current);
			delayRef.current += 200;
		});
		delayRef.current = 0;
	}, [loading, data, autoscroll, scrollToBottom]);

	return (
		<Document title={"Usuarios | La penca"} className="page-users">
			<div className="container p-4">
				{loading && (<DisplaySpinner />)}
				<div className="row my-2">
					<div className="col-12">
						<h2 className="fs-4 d-flex justify-content-sm-end justify-content-between align-items-center w-100 border-bottom border-1 border-secondary text-start m-0 py-2 border-opacity-50">
							<span className="flex-fill text-nowrap me-4">
								<i className="bi bi-list-ul me-2 fs-5" />
								<span className="d-inline-block">
									Usuarios
								</span>
							</span>

							<div className="d-flex flex-nowrap justify-content-center align-items-center w-50 w-sm-100">
								<div className="search-input input-group input-group-sm rounded-pill flex-nowrap border-0 bg-light bg-opacity-75 ms-auto w-75 w-sm-100 lh-1">
									<div className="search-icon input-group-text border-0 rounded-end-pill p-0 px-1 bg-transparent">
										<i className="bi bi-search small mx-2 text-secondary" />
									</div>
									<input
										className="form-control form-control-sm border-0 rounded-start-pill p-0 px-1 bg-transparent lh-1"
										placeholder="Buscar usuarios"
										ref={inputSearchRef}
										onChange={onChange}
										type="text"
									/>
									<div className="input-group-text border-0 rounded-end-pill bg-transparent lh-1" style={{ padding: 2 }}>
										<span className="badge border border-1 border-primary border-opacity-25 bg-primary bg-opacity-25 rounded-pill d-flex align-items-center justify-content-center ms-auto" style={{ padding: 1 }}>
											<i className="bi bi-people-fill text-light text-opacity-75 border-primary align-self-center mx-2" style={{ fontSize: 20 }} />
											<span ref={totalCountRef} className="badge bg-primary border border-1 border-primary border-opacity-25 bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center text-center p-0" style={{ fontSize: 12, height: 25, width: 25, margin: 2 }}>
												{total.current}
											</span>
										</span>
									</div>
								</div>
							</div>
						</h2>
					</div>
				</div>

				<div className="row g-4 overflow-hidden py-4">
					{data?.map((user, index) => (
						<div className="col-6 col-md-3" key={index} ref={(ref) => usersRef.current[index] = ref} style={{ display: "none" }}>
							<Link className="w-100 text-decoration-none" to={'/user/' + user.id}>
								<div className="card h-100 text-center shadow">
									<img className="img-fluid card-img-top" src={getURL.concat(user.avatar)} alt="user-avatar" />
									<div className="card-footer d-flex align-items-center justify-content-between">
										<h2 className="m-0 small fs-6 text-start d-flex align-items-center w-100">
											<span className="badge rounded-pill bg-primary bg-opacity-50 px-3 py-1 h-auto lh-1 text-truncate" style={{ maxWidth: "90%" }}>
												{user.username}
											</span>
										</h2>
									</div>
								</div>
							</Link>
						</div>
					))}
				</div>
				<div ref={buttonRef} className={"mb-4 py-4 text-center"}>
					<button className="btn btn-outline-success d-inline-block load-more-btn px-4" onClick={loadMore} type="button">
						ver más
					</button>
				</div>
			</div>
		</Document>
	)
}

export default Users;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
// import { SocketProvider } from './SocketContext';
import * as serviceWorker from './serviceWorker';
import { ContextProvider } from './Context';
import { StrictMode } from 'react';

import 'leaflet/dist/leaflet.css';
import './scss/index.scss';
import 'animate.css';

import erudaInit from './erudaInit';
import colorLogs from './colorLogs';
// import envLogs from './envLogs';
import App from './App';

// envLogs();
colorLogs();
erudaInit();
// eventListeners();

const element = document.getElementById('root');
const root = ReactDOM.createRoot(element);

const router = createBrowserRouter([{ 
	element: <App />, 
	path: "*", 
}], {
	future: {
		v7_skipActionStatusRevalidation: true,
		v7_skipActionErrorRevalidation: true,
		v7_normalizeFormMethod: true,
		v7_partialHydration: true,
		v7_fetcherPersist: true,
	},
});

root.render(
	<ContextProvider>
		{/* <SocketProvider> */}
		<StrictMode>
			<RouterProvider router={router} future={{
				v7_relativeSplatPath: true,
				v7_startTransition: true,
			}} />
		</StrictMode>
		{/* </SocketProvider> */}
	</ContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

serviceWorker.register();

import { useEffect, useMemo, useContext, Fragment } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DisplaySpinner from './components/DisplaySpinner';
import ForgotPassword from './components/ForgotPassword';
import PasswordReset from './components/PasswordReset';
import Notifications from './components/Notifications';
import { Alert } from './components/Alert';
import NotAuthorized from './components/NotAuthorized';
import ContentDetail from './components/ContentDetail';
import BannerDetail from "./components/BannerDetail";
import EditContent from './components/EditContent';
import AddContent from './components/AddContent';
import EditBanner from "./components/EditBanner";
import AddBanner from './components/AddBanner';

import ScrollToTop from './components/ScrollToTop';
import Navigation from './components/Navigation';
import UserDetail from "./components/UserDetail";
import Contents from "./components/Contents";
import NotFound from './components/NotFound';
import Register from "./components/Register";
import Footer from './components/Footer';
import Users from './components/Users';
import About from "./components/About";
import Login from "./components/Login";
import Home from "./components/Home";
import User from "./components/User";

import { Context } from './Context';

const App = () => {
	const { context, isLoading, setBottom } = useContext(Context);
	// const location = useLocation();

	const isAuth = useMemo(() => {
		return Boolean(context?.user);
	}, [context]);

	const isAdmin = useMemo(() => {
		return Boolean(isAuth && context?.user?.role === "admin");
	}, [context, isAuth]);

	useEffect(() => {
		if (!isAdmin) return;
		// if (!isAdmin) return;
		// if (!location) return;
		// console.log('location:', location)
		console.log('isAdmin:', isAdmin)
	}, [isAdmin]);

	return (
		<Fragment>
			<Fragment>
				<main className="App-main h-auto">
					<Alert />
					<Navigation />
					{isLoading ? (<DisplaySpinner />) : (
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/about" element={<About />} />
							<Route path="/login" element={isAuth ? <Navigate to="/user" /> : <Login />} />
							<Route path="/contents" element={<Contents />} />
							<Route path="/content/add" element={isAdmin ? <AddContent /> : <Navigate to="/unauthorized" />} />
							<Route path="/content/:id" element={<ContentDetail />} />
							<Route path="/content/edit/:id" element={isAdmin ? <EditContent /> : <Navigate to="/unauthorized" />} />
							<Route path="/banner/add" element={isAdmin ? <AddBanner /> : <Navigate to="/unauthorized" />} />
							<Route path="/banner/:id" element={<BannerDetail />} />
							<Route path="/banner/edit/:id" element={isAdmin ? <EditBanner /> : <Navigate to="/unauthorized" />} />
							<Route path="/users" element={<Users />} />
							<Route path="/password-reset" element={<PasswordReset />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/user" element={isAuth ? <User /> : <Navigate to="/login" />} />
							<Route path="/user/:id" element={<UserDetail />} />
							<Route path="/unauthorized" element={<NotAuthorized />} />
							<Route path="/register" element={<Register />} />]
							<Route path='*' element={<NotFound />} />
						</Routes>
					)}
				</main>
				<Footer date={context?.config?.date} />
				<Notifications data={context?.userNotifications} />
				<ScrollToTop />
			</Fragment>
			<hr className="border-0 m-0" ref={setBottom} />
		</Fragment>
	);
}

export default App;

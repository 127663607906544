import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import HttpService, { getURL } from "../http-common";
import { useNavigate } from "react-router-dom";
import { Context } from '../Context';
import { Toast } from "bootstrap";

const Notifications = ({ data = null }) => {
	const { context, setContext } = useContext(Context);
	const [toastData, setToastData] = useState(data);
	const navigate = useRef(useNavigate());
	const toastsContainer = useRef(null);
	const toastsWrapper = useRef(null);
	const buttonRef = useRef(null);
	const timeoutId = useRef(null);
	const hideDelayRef = useRef(0);
	const delayRef = useRef(0);

	const getBackgroundColor = useCallback((type) => {
		switch (type) {
			case "content-create": return "bg-success";
			case "content-update": return "bg-warning";
			case "content-delete": return "bg-danger";
			default: return "bg-info";
		}
	}, []);

	const notificationsViewAll = useCallback(async () => {
		try {
			toastsWrapper.current.style.display = "none";
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/notifications/viewed"),
				method: "post",
				body: {
					userId: context?.user?.id,
					viewed: true,
				},
			});
			if (result?.data) {
				setContext((state) => ({
					...state,
					user: {
						...state.user,
						notifications: []
					}
				}));
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [context, setContext]);

	const notificationView = useCallback(async (event, item) => {
		event.preventDefault();
		try {
			const { id } = item;
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/notifications/" + id),
				body: { viewed: true },
				method: "post",
			});
			if (result?.data) {
				console.log("result?.data:", result?.data)
				setContext((state) => ({
					...state,
					user: {
						...state.user,
						notifications: state.user.notifications.map((item) => {
							return (id === item.id) ? result.data : item;
						})
					}
				}));
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [setContext]);

	useEffect(() => {
		if (!data?.length) return;
		if (!toastsContainer?.current) return;
		toastsWrapper.current.style.display = "none";
		setToastData(data);
	}, [data]);

	useEffect(() => {
		if (!toastsContainer?.current) return;
		if (!context?.user?.notifications?.length) return;
		const viewed = context?.user?.notifications.filter(({ viewed }) => !viewed);
		toastsWrapper.current.style.display = "none";
		if (!viewed.length) return;
		console.log("viewed:", viewed);
		setToastData(viewed);
	}, [context]);

	useEffect(() => {
		if (!toastData?.length) return;
		if (!toastsWrapper?.current) return;
		if (!toastsContainer?.current) return;
		toastsContainer.current.innerHTML = null;

		const link = (event, item) => {
			event.preventDefault();
			navigate.current("/content/" + item.data.itemId);
		};

		const close = (event, item) => {
			event.stopPropagation();
			event.preventDefault();
			notificationView(event, item);
		};

		const appendAlert = (title, message, type, link, close, callback) => {
			const wrapper = document.createElement('div');
			wrapper.innerHTML = [
				`<div class="toast toast-${type} mb-1 toast-link" role="alert" aria-live="assertive" aria-atomic="true" style="cursor:pointer" data-bs-theme="dark">`,
				`   <div class="toast-header text-white fs-xs p-2 py-1 text-nowrap overflow-hidden position-relative">`,
				`   	<span class="p-1 me-2 rounded-circle ${type}"></span>`,
				`   	<div>${title}</div>`,
				'   	<button type="button" class="btn-close small btn-close-white ms-auto px-2 my-1" aria-label="Close"></button>',
				'   </div>',
				'   <div class="toast-body text-white pt-1">',
				`   	<div>${message}</div>`,
				'   </div>',
				'</div>'
			].join('');
			const closeButton = wrapper.querySelector('.btn-close');
			const toastLink = wrapper.querySelector('.toast-link');
			if (toastLink) toastLink.addEventListener('click', link);
			if (closeButton) closeButton.addEventListener('click', close);
			const node = wrapper.childNodes[0];
			toastsContainer.current.append(node);
			callback?.(node);
		};

		// console.log("toastData:", toastData);

		timeoutId.current = setTimeout(() => {
			toastsContainer.current.innerHTML = null;
			toastsWrapper.current.style.display = "block";
			buttonRef.current.style.animationDuration = ".2s";
			buttonRef.current.classList.remove("show", "animate__animated", "animate__bounceOutRight");
			buttonRef.current.classList.add("show", "animate__animated", "animate__bounceInRight");
			toastData.forEach((item, index, array) => {
				const type = getBackgroundColor(item.type);
				appendAlert(item.text, item.data.itemName, type, (event) => link(event, item), (event) => close(event, item), (toastNode) => {
					const toast = Toast.getOrCreateInstance(toastNode, { autohide: false, animation: false });
					setTimeout(() => {
						toastNode.style.animationDuration = ".4s";
						toastNode.classList.add("show", "animate__animated", "animate__fadeInRight");
						toastNode.addEventListener('animationend', () => {
							toastNode.classList.remove("animate__animated", "animate__fadeInRight");
							toast.show();
							setTimeout(() => {
								toastNode.classList.add("animate__animated", "animate__bounceOutRight");
								toastNode.addEventListener('animationend', () => {
									toastNode.classList.remove("animate__animated", "animate__bounceOutRight");
									toast.hide();
									if (array.length === (index + 1)) {
										buttonRef.current.classList.remove("animate__animated", "animate__bounceInRight");
										buttonRef.current.classList.add("animate__animated", "animate__bounceOutRight");
										toastsWrapper.current.style.display = "none";
									}
								}, { once: true });
							}, hideDelayRef.current);
						}, { once: true });
					}, delayRef.current);
					hideDelayRef.current = delayRef.current + 4000;
					delayRef.current += 300;
				});
			});
			delayRef.current = 0;
		}, 200);

		return () => {
			clearTimeout(timeoutId.current);
		}
	}, [toastData, getBackgroundColor, notificationView]);

	return (
		<div ref={toastsWrapper} style={{ zIndex: 999999, width: 300, display: "none" }} className="position-fixed bottom-0 end-0 p-2">
			<div ref={toastsContainer} className="d-flex flex-column justify-content-end align-items-center w-100" />
			<button
				type="button"
				ref={buttonRef}
				onClick={notificationsViewAll}
				className="btn btn-sm py-1 w-100 bg-black d-flex align-items-center justify-content-center fade border-0">
				<span className="fs-xs text-light text-opacity-75">ocultar todo</span>
				<i className="bi bi-eye text-success p-0 ms-2"></i>
			</button>
		</div>
	);
};

export default Notifications;

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const random = (min = 1, max = 10) => {
	return Math.floor(Math.random() * (max - min - 1)) + min;
};

export const getDate = (date) => {
	return new Date(date).toLocaleDateString("es-UY");
};

export const getUrlParam = (param) => {
	const searchParams = window.location.search;
	const urlParams = new URLSearchParams(searchParams);
	return urlParams.get(param);
};

export const randomString = (length) => {
	var result = "";
	for (var i = 0; i < length; ++i) {
		result += alphabet[Math.floor(alphabet.length * Math.random())];
	}
	return result;
};

export const cropString = (str, n = 100) => {
	if (str.length <= n) {
		return str;
	}
	return str.slice(0, n).concat(" ...");
};

export const cropWords = (str, num = 10) => {
	const words = str.split(' ');
	if (words.length <= num) return str;
	return words.slice(0, num).join(' ').concat(" ...");
};

export const addStyle = (styles, id = null) => {
	var style = document.getElementById(id);
	if (!style) {
		style = document.createElement('style');
		style.id = id;
		var cssString = '';
		for (var selector in styles) {
			if (styles.hasOwnProperty(selector)) {
				cssString += selector + ' {';
				for (var property in styles[selector]) {
					if (styles[selector].hasOwnProperty(property)) {
						cssString += property + ': ' + styles[selector][property] + '; ';
					}
				}
				cssString += '} ';
			}
		}
		if (style.styleSheet) {
			style.styleSheet.cssText = cssString;
		} else {
			style.innerHTML = cssString;
		}
		document.head.appendChild(style);
	}
};

export const getPathTo = (element) => {
	if (typeof element === 'string') {
		return element;
	} else {
		if (!element.tagName) return '';
		const tagName = element.tagName.toLowerCase();
		if (element.id) {
			return tagName + '#' + element.id;
		} else {
			const classes = Array.from(element.classList).join('.');
			const selector = (classes ? tagName + '.' + classes : tagName);
			return selector;
		}
	}
};

export const elementInViewport = (element) => {
	const bounds = element.getBoundingClientRect();
	const viewWidth = document.documentElement.clientWidth;
	const viewHeight = document.documentElement.clientHeight;
	if (bounds['left'] < 0) return false;
	if (bounds['top'] < 0) return false;
	if (bounds['right'] > viewWidth) return false;
	if (bounds['bottom'] > viewHeight) return false;
	return true;
};

export const showPositions = (x, y, options = {}) => {
	const { color = 'green', delay = 100 } = options;
	const div = document.createElement('div');
	div.style.background = color;
	div.style.borderRadius = "50%";
	div.style.position = "fixed";
	div.style.height = "20px";
	div.style.left = x + 'px';
	div.style.width = "20px";
	div.style.top = y + 'px';
	div.style.zIndex = 9999;
	div.style.opacity = 0.8;
	document.body.appendChild(div);

	const fadeEffect = () => {
		div.style.opacity = 1;
		const interval = setInterval(() => {
			if (div.style.opacity > 0) {
				div.style.opacity -= 0.1;
			} else {
				clearInterval(interval);
				document.body.removeChild(div);
			}
		}, 10);
	};

	if (!delay) return;
	setTimeout(fadeEffect, delay);
};

import HttpService, { getURL, http } from "../http-common";

const upload = async (file, path, onUploadProgress) => {
	const formData = new FormData();
	await formData.append("file", file);
	const result = await http.post(getURL.concat(path), formData, {
		onUploadProgress,
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	if (result?.data) {
		console.log('FileUploadService data', result.data);
		return result.data;
	}
	if (result?.message) {
		console.log('FileUploadService message', result.message);
		// return result.message;
	}
};

const getFiles = async () => {
	const result = await HttpService.fetchData({
		url: getURL.concat("/api/files"),
	});
	return result;
};

const FileUploadService = {
	upload,
	getFiles,
};

export default FileUploadService; 

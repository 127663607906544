import { Link } from "react-router-dom";

const NotAuthorized = () => {
	return (
		<div className="container p-4">
			<div className="text-center">
				<h1 className="display-4 my-4 py-2">Not Authorized</h1>
				<p className="fs-6">Go to: <Link to='/'>Home</Link></p>
			</div>
		</div>
	)
}
export default NotAuthorized;

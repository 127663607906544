import { Fragment, useRef, useContext, useEffect } from 'react';
import { ListGroup, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getURL } from "../http-common";
import { Context } from "../Context";

const ContentsList = ({ data = null, styles = {}, detail = false }) => {
	const { setSections } = useContext(Context);
	const sectionsRef = useRef({});

	const itemStyles = (src) => ({
		backgroundImage: 'url(' + getURL.concat(src) + ')',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: '100%',
		paddingTop: "100%",
		width: "100%",
	});

	useEffect(() => {
		if (!Object.keys(sectionsRef?.current).length) return;
		setSections(sectionsRef.current);
	}, [setSections, data]);

	return (
		<Fragment>
			<ListGroup variant="flush" className="m-0 sections" as='ul'>
				{data?.map((item, index) => (
					<ListGroup.Item id={'section-' + index} ref={(ref) => sectionsRef.current['section-' + index] = ref} as="li" key={index} className={"section-content border-0 p-0 bg-" + (item.layout === "left" ? "primary" : "white")} style={styles}>
						<div className="container p-0">
							<div className="row g-0">
								<div className={"col-md-6 d-flex position-relative order-first order-md-" + (item.layout === "left" ? "last" : "first")}>
									<div className={"card px-4 px-md-5 text-secondary-emphasis overflow-hidden h-auto border-0 bg-transparent my-auto " + (item.layout === "left" ? "pe-md-0" : "ps-md-0")}>
										<div className="card-header border-0 bg-transparent px-0">
											<h3 className="m-0 py-3 d-flex justify-content-between align-items-center text-orange lh-1">
												{(detail === true) ? (
													<Link to={"/content/" + item.id} className="nav-link">
														<span className="d-md-flex align-items-baseline">
															<img className="me-3" src={getURL.concat('/icons/' + item.icon + '.svg')} alt="icon" style={{ width: 30 }} />
															<span className="card-header-text">
																{item.name}
															</span>
														</span>
													</Link>
												) : (
													<span className="d-flex align-items-baseline">
														<img className="me-3" src={getURL.concat('/icons/' + item.icon + '.svg')} alt="icon" style={{ width: 30 }} />
														<span className="card-header-text lh-1">
															{item.name}
														</span>
													</span>
												)}
											</h3>
										</div>
										<div className={"card-body px-0 text-" + (item.layout === "left" ? "light" : "body")}>
											<div className="mb-2 lh-sm d-linebreak">
												{item.text}
											</div>
										</div>
										{(detail === true) && (
											<div className="card-footer border-0 bg-transparent p-0">
												<Link to={"/content/" + item.id} className="btn btn-sm btn-primary d-inline-flex align-items-center lh-1 pe-0">
													more
													<i className="bi bi-plus mx-2 fs-4" />
												</Link>
											</div>
										)}
									</div>
								</div>
								<div className={"col-md-6 order-last order-md-" + (item.layout === "left" ? "first" : "last")}>
									{((JSON.parse(item?.image).length) === 1) ? (
										<div style={itemStyles(JSON.parse(item.image)[0])} />
									) : (
										<Carousel className="h-100" variant="dark">
											{JSON.parse(item?.image).map((src, index) => (
												<Carousel.Item key={index} className="position-relative">
													<div style={itemStyles(src)} />
												</Carousel.Item>
											))}
										</Carousel>
									)}
								</div>

							</div>
						</div>
					</ListGroup.Item>
				))}
			</ListGroup>
		</Fragment >
	);
};

export default ContentsList;

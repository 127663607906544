import { useRef, useCallback, useState, useEffect } from "react";
import { alertService } from '../services/AlertService';
import HttpService, { getURL } from "../http-common";
import DisplaySpinner from "./DisplaySpinner";

const ForgotPassword = () => {
	const [loading, setLoading] = useState(false);
	const invalidFeedbackRef = useRef(null);
	const formElement = useRef(null);

	const handleSubmit = useCallback(async (event) => {
		try {
			setLoading(true);
			event.preventDefault();
			const form = formElement.current;
			form.classList.add('is-submited');
			const data = Array.from(form.elements)
				.filter((input) => input.name)
				.reduce((obj, input) => Object.assign(obj, {
					[input.name]: input.value
				}), {});
			const result = await HttpService.fetchData({
				body: JSON.stringify(data),
				url: getURL.concat("/api/forgot-password"),
				method: "post",
			});
			if (result?.data) {
				console.log("result?.data:", result?.data);
				setLoading(false);
				if (result.message) {
					alertService.success(result.message, {
						autoClose: false,
					});
					console.log(result.message);
					setLoading(false);
				}
			} else {
				if (result.message) {
					setLoading(false);
					console.log(result.message);
					alertService.error(result.message, {
						keepAfterRouteChange: true,
						autoClose: false,
					});
				}
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [setLoading]);

	useEffect(() => {
		if (!formElement?.current) return;
		const form = formElement.current;
		form.autocomplete = "off";
		form.noValidate = true;
		for (const field of form.elements) {
			field.autocomplete = "off";
			if (!field.validity.valid) {
				field.nextSibling.innerHTML = field.validationMessage;
			}
			field.addEventListener("input", (event) => {
				// if (event.inputType === "insertReplacementText") return;
				field.nextSibling.innerHTML = field.validationMessage;
				form.classList.add('was-validated')
			});
		}
		form.addEventListener('submit', (event) => {
			if (!form.checkValidity()) {
				event.stopPropagation();
				event.preventDefault();
			}
			form.classList.add('was-validated')
		});
	}, []);

	return (
		<div className="container p-4">
			{loading && (<DisplaySpinner />)}
			<div className="row my-4">
				<div className="col-12">
					<h2 className="fs-4 d-flex justify-content-between align-items-center w-100 border-bottom border-1 border-secondary text-start m-0 py-2">
						<span className="flex-fill">
							<i className="bi bi-person me-2 fs-4"></i>
							Recuperar contraseña
						</span>
					</h2>
				</div>
			</div>
			<form className="needs-validation my-5 shadow border border-secondary border-opacity-10 mx-auto col-12 col-md-8 col-lg-6 p-4 rounded-2" ref={formElement} onSubmit={handleSubmit}>
				<div className="input-group ">
					<input name="email" type="email" className="form-control" id="email1" placeholder="email" aria-describedby="emailHelp" required />
					<button type="submit" className="btn btn-success">Enviar</button>
					<div ref={invalidFeedbackRef} className="invalid-feedback" />
				</div>
			</form>
		</div>
	)
}

export default ForgotPassword;

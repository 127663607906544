import { useState, useRef } from "react";
// import '../react-input-number.css';

const InputNumber = ({ id, name, type, max, min, value, placeholder, className = "", height = "auto", disabled = false }) => {
	const [count, setCount] = useState(value);
	const inputRef = useRef(null);

	const decrementCount = (event) => {
		event.preventDefault();
		if (disabled) return;
		setCount((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
		inputRef.current.focus();
	};

	const incrementCount = (event) => {
		event.preventDefault();
		if (disabled) return;
		setCount((prevValue) => (prevValue === '' ? 1 : prevValue + 1));
		inputRef.current.focus();
	};

	const onChange = (event) => {
		const newValue = event.target.value;
		setCount(newValue === '' ? '' : parseInt(newValue, 10));
	};

	const onKeyDown = (event) => {
		const { key, code, target: { value } } = event;
		const keys = [
			'ArrowUp',
			'ArrowDown',
			'ArrowLeft',
			'ArrowRight',
			'Backspace',
			'Delete',
			' ',
		]
		if (!isNaN(Number(key)) || (keys.includes(key) && code !== 'Space')) {
			const newValue = value.replace(/^0+/, '');
			event.target.value = newValue;
		} else {
			event.preventDefault();
		}
	};

	return (
		<div className="position-relative input-number" style={{ height }}>
			<input
				style={{ textAlign: 'center', appearance: 'textfield', paddingRight: 50, height }}
				placeholder={placeholder}
				className={className}
				onKeyDown={onKeyDown}
				onChange={onChange}
				disabled={disabled}
				ref={inputRef}
				value={count}
				type={type}
				name={name}
				max={max}
				min={min}
				id={id}
			/>
			<div style={{ width: 40, height }} className="btn-group-vertical position-absolute top-0 end-0 bottom-0 bg-secondary rounded-end-2">
				<button className="btn btn-sm btn-transparent border border-transparent p-0 px-2 rounded-2 rounded-start-0 rounded-bottom-0 fs-xs h-50" onClick={incrementCount} disabled={disabled}>
					<i className="bi bi-chevron-up"></i>
				</button>
				<button className="btn btn-sm btn-transparent border border-transparent p-0 px-2 rounded-2 rounded-start-0 rounded-top-0 fs-xs h-50" onClick={decrementCount} disabled={disabled}>
					<i className="bi bi-chevron-down"></i>
				</button>
			</div>
		</div>
	);
}

export default InputNumber;

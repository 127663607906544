const useLocalStorage = () => {
	const setLocalStorage = (key, value) => {
		try {
			window.localStorage.setItem(key, JSON.stringify(value));
		} catch (error) {
			console.error(error);
		}
	};

	const getLocalStorage = (key) => {
		try {
			if (key) {
				const item = window.localStorage.getItem(key);
				return item ? JSON.parse(item) : null;
			}
			return window.localStorage;
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	return [setLocalStorage, getLocalStorage];
}

export default useLocalStorage;

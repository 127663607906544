import { Link } from 'react-router-dom';
import fb from '../fb.svg';
import ig from '../ig.svg';
import x from '../x.svg';

const Footer = ({ date }) => {

	return (
		<footer className="footer position-fixed position-md-static py-2 bg-primary shadow-lg start-0 end-0 bottom-0 d-flex align-items-center" style={{ height: 60 }}>
			<div className="container p-sm-0">
				<div className="row row-cols-3 d-flex flex-wrap justify-content-between align-items-center g-0">
					<div className="col w-50">
						<ul className="nav justify-content-start flex-nowrap">
							<li className="nav-item">
								<Link to="/" className="nav-link p-0 text-light">
									<img width={30} alt="facebo0ok" className="img-fluid logo" src={fb} />
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/" className="nav-link p-0 mx-3 text-light">
									<img width={30} alt="instagram" className="img-fluid logo" src={ig} />
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/" className="nav-link p-0 text-light">
									<img width={30} alt="x" className="img-fluid logo" src={x} />
								</Link>
							</li>
						</ul>
					</div>
					<div className="col w-50 text-end">
						<span style={{ fontSize: 12 }} className="align-middle">
							<p className='m-0 text-light text-opacity-75'>Copyright © {date?.year}</p>
						</span>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

import { MapContainer, TileLayer, LayersControl, Marker, Popup } from 'react-leaflet';
import { useEffect, useState, useRef } from 'react';
import { getURL } from "../http-common";
import L from 'leaflet';

L.Icon.Default.imagePath = getURL.concat("/");

const points = [{
	lat: -34.80817,
	lng: -56.25843,
	title: 'Saincor SA'
}, {
	lat: -34.81699,
	lng: -56.26091,
	title: 'Saincor SA 2'
}];

const MapView = () => {
	const [isReady, setIsReady] = useState(false);
	// const [markers, setMarkers] = useState([]);
	// const [done, setDone] = useState(false);
	const [map, setMap] = useState(null);
	const markersRef = useRef([]);

	useEffect(() => {
		if (!map) return;
		if (!isReady) return;
		// console.log('map', map);
		setTimeout(() => {
			map.flyTo(points[0], 14)
		}, 1000);
		markersRef.current.forEach((marker) => {
			marker.openPopup();
		});
	}, [map, isReady]);

	return (
		<MapContainer
			zoom={12}
			tap={false}
			ref={setMap}
			dragging={false}
			center={points[0]}
			scrollWheelZoom={false}
			className='TEST'
			whenReady={() => setIsReady(true)}>
			<LayersControl>
				<LayersControl.BaseLayer checked name="Google Maps">
					<TileLayer
						attribution="Google Maps"
						url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
						subdomains={['mt0','mt1','mt2','mt3']}
					/>
				</LayersControl.BaseLayer>
				<LayersControl.BaseLayer name="Google Maps Satellite">
					<TileLayer
						attribution="Google Maps Satellite"
						url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
						subdomains={['mt0','mt1','mt2','mt3']}
					/>
				</LayersControl.BaseLayer>
				<LayersControl.BaseLayer name="Open Street Map">
					<TileLayer
						attribution="Open Street Map"
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
				</LayersControl.BaseLayer>
			</LayersControl>

			{points.map(({ lat, lng, title }, index) => (
				<Marker
					key={index}
					position={{ lat, lng }}
					ref={(ref) => markersRef.current[index] = ref}>
					<Popup autoClose={false}>{title}</Popup>
				</Marker>
			))}

		</MapContainer>
	)
}

export default MapView;

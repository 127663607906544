import { useRef, useContext, useCallback, useState, useEffect } from "react";
import { alertService } from '../services/AlertService';
import HttpService, { getURL } from "../http-common";
import { Link } from 'react-router-dom';
import { Context } from '../Context';

const Login = () => {
	const { updateContext, setIsLoading, isLoading } = useContext(Context);
	const [show, setShow] = useState(false);
	const passwordRef = useRef(null);
	const formElement = useRef(null);
	const buttonRef = useRef(null);

	const toggleShow = useCallback(() => {
		const inputPassword = passwordRef?.current;
		if (inputPassword?.getAttribute("type") === "password") {
			inputPassword.setAttribute("type", "text");
			setShow(true);
		} else {
			inputPassword.setAttribute("type", "password");
			setShow(false);
		}
		inputPassword.focus();
	}, [passwordRef]);

	const handleSubmit = useCallback(async (event) => {
		try {
			event.preventDefault();
			buttonRef.current.disabled = true;
			const form = formElement.current;
			form.classList.add('is-submited');
			const data = Array.from(form.elements)
				.filter((input) => input.name)
				.reduce((obj, input) => Object.assign(obj, {
					[input.name]: input.value
				}), {});
			const result = await HttpService.fetchData({
				body: JSON.stringify(data),
				url: getURL.concat("/api/login"),
				method: "post",
			});
			// setIsLoading(true);
			if (result) {
				buttonRef.current.disabled = false;
				if (result.data) {
					console.log('result.data', result.data);
					setIsLoading(true);
					updateContext();
					if (result.message) {
						console.log('Login:', result.message);
						if (!isLoading) {
							alertService.success(result.message, {
								keepAfterRouteChange: true,
							});
						}
					}
				} else {
					if (result.message) {
						alertService.error(result.message);
						console.log(result.message);
					}
				}
			}
		} catch (error) {
			buttonRef.current.disabled = false;
			console.error(error.message);
			setIsLoading(false);
		}
	}, [updateContext, setIsLoading, isLoading]);

	useEffect(() => {
		if (!formElement?.current) return;
		const form = formElement.current;
		form.noValidate = true;
		for (const field of form.elements) {
			if (!field.validity.valid) {
				field.nextSibling.innerHTML = field.validationMessage;
			}
			field.addEventListener("input", (event) => {
				if (!event.inputType) return;
				field.nextSibling.innerHTML = field.validationMessage;
				form.classList.add('was-validated')
			});
		}
		form.addEventListener('submit', (event) => {
			if (!form.checkValidity()) {
				event.stopPropagation();
				event.preventDefault();
			}
			form.classList.add('was-validated')
		});
	}, []);


	return (
		<div className="container p-4">
			<div className="row my-4 d-md-none">
				<div className="col-12">
					<h2 className="fs-4 d-flex justify-content-between align-items-center w-100 border-bottom border-1 border-secondary text-start m-0 py-2 border-opacity-50">
						<span className="flex-fill">
							<i className="bi bi-person me-2 fs-4"></i>
							Ingresar
						</span>
					</h2>
				</div>
			</div>
			<form className="needs-validation my-5 shadow border border-secondary border-opacity-10 mx-auto col-12 col-md-8 col-lg-6 p-4 rounded-2" ref={formElement} onSubmit={handleSubmit}>
				<div className="mb-4">
					<input name="email" type="email" className="form-control" id="email1" placeholder="email" aria-describedby="emailHelp" required />
					<div className="invalid-feedback" />
				</div>
				<div className="mb-3">
					<div className="position-relative mb-3">
						<div className="position-absolute bottom-0 end-0 top-0 p-2 me-1" onClick={toggleShow}>
							<i className={"bi bi-" + (show ? "eye-slash" : "eye")}></i>
						</div>
						<input ref={passwordRef} name="password" type="password" placeholder="contraseña" className="form-control" id="inputPassword" required />
						<div className="invalid-feedback" />
					</div>
				</div>
				<div className="row justify-content-between align-items-center">
					<div className="col-auto">
						<button ref={buttonRef} type="submit" className="btn btn-success my-2">Ingresar</button>
					</div>
					<div className="col">
						<Link className="btn btn-link" to="/forgot-password">
							recuperar contraseña
						</Link>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Login;

import HttpService, { getURL } from "../http-common";
import UserService from "./UserService";

class WebPushService {
	static REACT_APP_PUBLIC_VAPID_KEY = 'BMdc2jTnvcSOQSAGLU68cIGwfIkJp_Jh_vBce5tIjDB0AuVhMGvpXWT_bfU2-EG5p3qX5q8IOJ5fFqistkcnye8';

	static REACT_APP_API_URL = getURL;

	static urlBase64ToUint8Array(base64String) {
		const padding = "=".repeat((4 - base64String.length % 4) % 4);
		const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);
		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i)
		}
		return outputArray;
	}

	static async sendSubscription(subscriptionData) {
		const user = await UserService.getUser();
		const subscription = subscriptionData.toJSON();
		const result = await HttpService.fetchData({
			url: getURL.concat("/webpush/subscribe"),
			body: { user, subscription },
			method: 'post',
		});
		if (result?.data) {
			return result.data;
		}
	}

	static hasPermission() {
		return Notification.permission === "granted";
	}

	static async requestPermission() {
		return await Notification.requestPermission();
	}

	static async getRegistration() {
		const registration = await navigator.serviceWorker.getRegistration();
		if (registration) {
			// console.log("ServiceWorker registration found.");
			return registration;
		};
	}

	static async getSubscription() {
		if (!navigator.serviceWorker) return null;
		const registration = await navigator.serviceWorker.ready;
		const subscription = await registration.pushManager.getSubscription();
		// console.log("subscription:", subscription);
		return subscription || null;
	}

	static async subscribe() {
		if ('serviceWorker' in navigator) {
			if (!navigator.serviceWorker) throw new Error("serviceWorker error");
			return navigator.serviceWorker.ready.then((registration) => {
				console.log("WebPushService ~ registration:", registration);
				if (!registration.pushManager) {
					console.error('Push manager unavailable.');
					return;
				}
				return registration.pushManager.getSubscription().then(async (subscription) => {
					if (subscription === null) {
						console.log('No subscription detected, make a request.')
						return registration.pushManager.subscribe({
							applicationServerKey: this.urlBase64ToUint8Array(this.REACT_APP_PUBLIC_VAPID_KEY),
							userVisibleOnly: true,
						}).then(async (newSubscription) => {
							console.log('New subscription added.')
							const response = await this.sendSubscription(newSubscription)
							console.log(response.message);
							return response;
						}).catch((error) => {
							console.error('An error ocurred during the subscription process.', error);
							throw error;
						})
					} else {
						// console.log('Existed subscription detected.');
						const response = await this.sendSubscription(subscription)
						console.log(response.message);
						return response;
					}
				});
			}).catch((error) => {
				console.error('An error ocurred during Service Worker registration.', error);
				throw error;
			});
		}
	}

	static async unsubscribe() {
		const subscription = await this.getSubscription();
		if (subscription) {
			return subscription.unsubscribe().then((bool) => {
				if (!bool) return null;
			}).then(() => {
				console.log('User is unsubscribed');
				return subscription.toJSON();
			}).catch((error) => {
				console.error('Unsubscription error: ', error);
				throw error;
			});
		}
	}
}

export default WebPushService;

import { useState, useEffect, useRef, useContext, useCallback } from "react";
import { alertService } from '../services/AlertService';
import HttpService, { getURL } from "../http-common";
import { useParams } from 'react-router-dom';
import InputNumber from "./InputNumber";
import { Context } from "../Context";
import Uploader from "./Uploader";

const EditBanner = () => {
	const { context, setIsLoading, getContents } = useContext(Context);
	// const [isSelectedFiles, setIsSelectedFiles] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isUploaded, setIsUploaded] = useState(false);
	// const [loading, setLoading] = useState(false);
	const [content, setContent] = useState(null);
	const [images, setImages] = useState([]);
	const [order, setOrder] = useState(0);
	const formElement = useRef(null);
	const params = useParams();

	const handleSubmit = useCallback(async (event) => {
		console.log("event:", event)
		event.preventDefault();
		try {
			if (!!images?.length && isSubmitted) {
				alertService.warn("upload images before send!", { autoClose: false });
				setIsSubmitted(true);
			} else {
				const form = formElement.current;
				console.log("form:", form)
				const data = Array.from(form.elements)
					.filter((input) => input.name)
					.reduce((obj, input) => {
						return Object.assign(obj, { [input.name]: input.value })
					}, {});

				if (images?.length === 0) {
					const parts = images?.[0]?.split('/');
					data.userId = context?.user.id;
					if (!!parts?.length) {
						// data.image = [];
						// data.image[0] = parts[parts.length - 1];
						// data.image = JSON.stringify(data.image);
						data.image = parts[parts.length - 1];
					}
				} else {
					data.image = images.map((img, index) => {
						const parts = img?.split('/');
						img = parts[parts.length - 1];
						return img;
					})
				}

				console.log("🚀 ~ handleSubmit ~ data:", data);

				if (!!Object.keys(data)) {
					const result = await HttpService.fetchData({
						url: getURL.concat("/api/banners/edit/").concat(params.id),
						body: JSON.stringify(data),
						method: "post",
					});
					if (result) {
						setIsSubmitted(true);
						if (result.data) {
							getContents();
							if (result.message) {
								console.log(result.message);
								alertService.success(result.message, {
									autoClose: false,
									link: {
										path: "/banner/" + result?.data?.id,
										text: result?.data?.name
									}
								});
							}
						} else {
							if (result.message) {
								console.error(result.message);
								alertService.error(result.message, {
									autoClose: false,
								});
							}
						}
					}
				}
			}
		} catch (error) {
			alertService.warn(error.message);
			console.error(error.message);
			setIsLoading(false);
		}
	}, [context, images, isSubmitted, params, setIsLoading, getContents]);

	const getContent = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/banners/").concat(params.id),
			});
			if (result?.data) {
				console.log('getContent', result.data);
				// setImages((state) => ([...state, result.data.image]));
				setContent(result.data);
				if (result.data?.order) {
					setOrder(result.data?.order);
				}
				if (result.data?.image) {
					setImages(JSON.parse(result.data?.image));
				}
			}
			if (result.message) {
				alertService.warn(result.message);
				console.log(result.message);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [params]);

	useEffect(() => {
		if (!isSubmitted) return;
		setIsSubmitted(false);
	}, [isSubmitted]);

	useEffect(() => {
		if (!isUploaded) return;
		alertService.clear();
	}, [isUploaded]);

	useEffect(() => {
		setImages([]);
		getContent();
	}, [getContent]);

	return (
		<div className="container p-4">
			<div className="rpw mb-4">
				<h1 className="display-4">Edit Content {content?.name}</h1>

				<div className="card">
					<div className="card-body">
						<h5 className="card-title">To add content</h5>
						<p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
						{content && (
							<form ref={formElement} onSubmit={handleSubmit} noValidate>

								<div className="mb-3">
									<label htmlFor="name" className="form-label">
										Name:
									</label>
									<div className="col-md-8">
										<input
											type="text"
											name="name"
											className="form-control"
											defaultValue={content?.name}
											required
										/>
										<div className="invalid-feedback">
											Valid first name is required.
										</div>
									</div>
								</div>

								<div className="mb-3">
									<label htmlFor="text" className="form-label">
										Text:
									</label>
									<div className="col-md-8">
										<textarea
											rows={10}
											name="text"
											className="form-control d-linebreak"
											defaultValue={content?.text}
										/>
										<div className="invalid-feedback">
											Valid text is required.
										</div>
									</div>
								</div>

								<div className="mb-3">
									<label htmlFor="order" className="form-label">
										Order:
									</label>
									<div className="col-md-8">
										<InputNumber
											className="form-control"
											value={order}
											name="order"
											// placeholder="auto"
											type="number"
											// required
											step={1}
											min={0}
										/>
										<div className="invalid-feedback">
											Valid order is required.
										</div>
									</div>
								</div>

								<div className="mb-3">
									<label className="form-label">Images</label>
									<div className="col-md-8">
										<Uploader
											multiple={false}
											path={"/api/upload-banner"}
											setUploaderData={setImages}
											onUploaded={setIsUploaded}
										/>
										{!!images.length && (
											<div className="row row-cols-4 g-2 my-2 g-1">
												{images.map((src, index) => (
													<div key={index} className="col-3 overflow-hidden">
														{/*
									  						<img className="preview img-fluid" src={getURL.concat(src.contains('upload/') ? src : '/upload/' + src)} alt="team" />
														*/}
														<img className="preview img-fluid" src={getURL.concat(src)} alt="team" />
													</div>
												))}
											</div>
										)}
									</div>
								</div>

								<button type="submit" className="btn btn-success my-2">Guardar</button>
							</form>
						)}
					</div>
				</div>
			</div>
		</div>
	)
};

export default EditBanner;

const imageLoader = (data, callback = null) => {
	if (!data?.length) return;

	var allLoaded = 0;

	const results = {
		success: [],
		error: []
	};

	const verifier = () => {
		allLoaded++;
		if (allLoaded === data.length) {
			results.counter = allLoaded;
			if (typeof callback === 'function') {
				callback?.(results);
			}
		}
	};

	data.forEach((src) => {
		const image = new Image();
		image.addEventListener("load", () => {
			results.success.push(src);
			verifier();
		}, false);
		image.addEventListener("error", () => {
			results.error.push(src);
			verifier();
		}, false);
		image.src = src;
	});
}

export default imageLoader;

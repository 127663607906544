import { useEffect, useState, Children } from 'react';

const Document = ({ title, className, children }) => {
	const [oldTitle] = useState(document.title);
	const [oldClassName] = useState(document.body.className);

	useEffect(() => {
		if (title) {
			document.title = title;
		}
		if (className) {
			const newClassName = `${oldClassName} ${className}`;
			document.body.className = newClassName.trim().replace('  ', ' ');
		}

		return () => {
			document.title = oldTitle;
			document.body.className = oldClassName;
		};
	}, [title, className, oldTitle, oldClassName]);

	return children ? Children.only(children) : null;
};

export default Document;

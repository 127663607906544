import { useState, useEffect, createContext, useCallback, useRef } from "react";
import HttpService, { getURL } from "./http-common";
import imageLoader from "./imageLoader";
import { socket } from "./socket";

const Context = createContext();

const debug = false;

const ContextProvider = ({ children }) => {
	const [isConnected, setIsConnected] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [sections, setSections] = useState(null);
	const [context, setContext] = useState(null);
	const bottomRef = useRef(null);
	const bannerRef = useRef(null);

	const setBottom = useCallback((element) => {
		console.color.warn('Context.js ~ setBottom', element, debug);
		bottomRef.current = element;
	}, []);

	const setBanner = useCallback((element) => {
		console.color.warn('Context.js ~ setBanner', element, debug);
		bannerRef.current = element;
	}, []);

	const getContents = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/contents"),
			});
			if (!!result?.data?.length) {
				return result?.data?.map(({ id, name, text, image }) => ({ id, name, text, image }));
			}
			if (result?.message) {
				console.log("result.message:", result.message)
			}
		} catch (error) {
			console.error(error.message);
		}
	}, []);

	const getBanners = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/banners"),
			});
			if (!!result?.data?.length) {
				return result?.data?.map(({ id, name, text, image }) => ({ id, name, text, image }));
			}
			if (result?.message) {
				console.log("result.message:", result.message)
			}
		} catch (error) {
			console.error(error.message);
		}
	}, []);

	const getUser = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/auth"),
			});
			if (result?.data) {
				return result.data;
			};
		} catch (error) {
			console.error(error.message);
		}
	}, []);

	const getApiConfig = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/config"),
			});
			if (result?.data) {
				return result.data;
			}
		} catch (error) {
			console.error(error.message);
		}
	}, []);

	const initContext = useCallback(async () => {
		console.color.message('Context.js ~ initContext', true);

		console.log('REACT_APP_BUILD_UUID', process.env.REACT_APP_BUILD_UUID);
		console.log('REACT_APP_BUILD_ENV 3', process.env.REACT_APP_BUILD_ENV);
		
		await Promise.all([
			await getApiConfig(),
			await getContents(),
			await getBanners(),
			await getUser(),
		]).then(([config = null, menu = null, banners = null, user = null]) => {
			const srcArray = [];
			menu?.concat(banners).forEach((item) => {
				const image = item?.image;
				if (!image) return;
				const jsonData = JSON.parse(image);
				srcArray.push(...jsonData.map((src) => getURL.concat(src)))
			});
			setContext((state) => ({
				...state,
				sections: {
					"section-offcanvas-logo": document.body,
					"section-navbar-logo": document.body
				},
				connected: socket?.connected,
				banners,
				config,
				menu,
				user,
			}));
			return srcArray;
		}).then((data) => {
			imageLoader(data, () => {
				setIsLoading(false);
			});
		});
	}, [getApiConfig, getContents, getBanners, getUser]);

	const updateContext = useCallback(() => {
		console.color.warn('Context.js ~ updateContext', true);
		initContext();
	}, [initContext]);

	const onConnect = useCallback(() => {
		console.log("onConnect:");
		setIsConnected(true);
	}, []);

	const onDisconnect = useCallback(() => {
		console.log("onDisconnect:");
		setIsConnected(false);
	}, []);

	const onConnectError = useCallback((error) => {
		console.error("onConnectError:");
		setIsConnected(false);
		if (socket.active) {
			console.warn("temporary failure, the socket will automatically try to reconnect");
		} else {
			console.log('onConnectError error', error.message);
		}
	}, []);

	const onContextData = useCallback((data = null) => {
		if (!data) return;
		console.log(":::::::::::::::::::::::::");
		console.log("onContextData data:", data);
		console.log(":::::::::::::::::::::::::");
		setContext((state) => ({
			...state,
			user: data,
		}));
	}, []);

	useEffect(() => {
		if (!initialized) {
			setInitialized(true);
			setIsLoading(true);
			initContext();
		} else {
			if (context && !isLoading) {
				console.color.info('Context.js ~ context: ', context, true);
			}
		}
	}, [initialized, context, initContext, isLoading]);

	useEffect(() => {
		if (!sections) return;
		setContext((state) => ({
			...state,
			sections: {
				...state.sections,
				...sections
			}
		}));
	}, [sections]);

	useEffect(() => {
		if (!context) return;
		if (!bottomRef?.current) return;
		setContext((state) => ({
			...state,
			bottomRef: bottomRef?.current,
		}));
		return () => {
			bottomRef.current = null;
		}
	}, [context]);

	useEffect(() => {
		if (!context) return;
		if (!bannerRef?.current) return;
		setContext((state) => ({
			...state,
			bannerRef: bannerRef?.current,
		}));
		return () => {
			bannerRef.current = null;
		}
	}, [context]);

	// useEffect(() => {
	// if (!socket) return;
	// setIsConnected(socket.connected);
	// }, [socket]);

	useEffect(() => {
		if (!debug) return;
		socket.onAny((eventName, data = null) => {
			console.log('onAny:', { name: [eventName], data });
		});
	}, []);

	useEffect(() => {
		if (!socket) return;
		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);
		socket.on('contextData', onContextData);
		socket.on('connect_error', onConnectError);
		return () => {
			socket.removeAllListeners();
			socket.off('connect', onConnect);
			socket.off('disconnect', onDisconnect);
			socket.off('contextData', onContextData);
			socket.off('connect_error', onConnectError);
		};
	}, [onConnect, onDisconnect, onContextData, onConnectError]);

	return (
		<Context.Provider value={{ context, socket, isLoading, isConnected, setContext, setIsLoading, updateContext, setBottom, setBanner, getContents, setSections }}>
			{children}
		</Context.Provider>
	)
}

export { Context, ContextProvider };

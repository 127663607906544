import { useEffect, useRef, useState, useCallback, useContext, Fragment } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import HttpService, { getURL } from "../http-common";
import { alertService } from '../services/AlertService';
import CloseButton from 'react-bootstrap/CloseButton';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import offcanvasLogo from '../offcanvas-logo.png';
import useWindowResize from '../useWindowResize';
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { Context } from '../Context';
import logo from '../logo.png';
import Swipy from 'swipyjs';

const Navigation = () => {
	const { context, isConnected, updateContext, setIsLoading } = useContext(Context);
	const [notifications, setNotifications] = useState(null);
	const [activeSection, setActiveSection] = useState(null);
	const [menuIsVisible, setMenuIsVisible] = useState(true);
	const [menuShow, setMenuShow] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [nodes, setNodes] = useState(null);
	const [menu, setMenu] = useState(null);
	const [user, setUser] = useState(null);
	const windowWidth = useWindowResize();
	const navbarNavRef = useRef(null);
	const initScroll = useRef(false);
	const timeoutId = useRef(null);
	const navbarRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();

	const onLogout = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/logout"),
			});
			if (result?.message) {
				console.color.message('Navbar.js ~ onLogout:', result.message);
				alertService.clear();
				navigate('/login');
				setIsLoading(true);
				updateContext();
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [setIsLoading, updateContext, navigate]);

	const getElementPositions = useCallback((element) => {
		if (!element) return;
		const rect = element.getBoundingClientRect();
		return rect.toJSON();
	}, []);

	const toggleVisibility = useCallback(() => {
		if (!context?.bannerRef) return;
		const scrollTop = window.scrollY || document.documentElement.scrollTop;
		// const clientHeight = document.documentElement.clientHeight;
		const bannerPositions = getElementPositions(context?.bannerRef);
		const bannerHeight = bannerPositions.height;
		if (scrollTop > bannerHeight) {
			navbarRef.current.classList.remove("bg-opacity-100");
			navbarRef.current.classList.add("bg-opacity-75");
		} else {
			navbarRef.current.classList.remove("bg-opacity-75");
			navbarRef.current.classList.add("bg-opacity-100");
		}
	}, [context, getElementPositions]);

	const toggleMenuActive = useCallback(() => {
		if (!nodes) return;
		if (!navbarRef?.current) return;
		const menuHeight = navbarRef.current.offsetHeight;
		Object.values(nodes).forEach((section) => {
			const rect = getElementPositions(section);
			const offset = menuHeight;
			// const offset = menuHeight * 1.5;
			// const offset = rect.height * 0.5;
			if (rect.top < offset && rect.bottom) {
				setActiveSection(section.id);
				// if (section.id === activeSection && menuIsVisible) {
				// if (initScroll.current) {
				// console.log('section', section.querySelector(".card-header-text")?.innerHTML)
				// console.log('window.innerHeight', window.innerHeight);
				// console.log('rect.top', Math.round(rect.top));
				// console.log('rect.bottom', Math.round(rect.bottom));
				// console.log('menuHeight', menuHeight);
				// console.log('offset', offset);
				// }
				// }
			}
		});
	}, [nodes, getElementPositions]);

	const onDropdownToggle = useCallback((show, meta) => {
		setMenuShow(!menuShow);
	}, [menuShow]);

	const toggleShow = useCallback(() => {
		setIsShow((state) => !state);
	}, []);

	const scrollTo = useCallback(({ currentTarget }) => {
		// const key = 'section-' + currentTarget?.id;
		const key = currentTarget.id.replace("menu-", "");
		const element = nodes?.[key];
		// console.log("element:", element)
		if (element) {
			const rect = getElementPositions(element);
			const scrollTop = window.scrollY || document.documentElement.scrollTop;
			const distance = Number(rect.top + scrollTop);
			setIsShow(false);
			window.scrollTo({
				top: Number(distance - 60),
				behavior: 'smooth'
			});
		}
	}, [nodes, getElementPositions]);

	const isSwipe = useCallback((element, classNames) => {
		const classArray = Array.isArray(classNames) ? classNames : [classNames];
		const hasClass = (el) => classArray.some(className => el.classList.contains(className))
		while (element) {
			if (element.classList && hasClass(element)) return true;
			element = element.parentElement;
		}
		return false;
	}, []);

	const onSwipe = useCallback(() => {
		const swipy = new Swipy(document.body);
		swipy.on('swipeleft', (event) => {
			clearTimeout(timeoutId.current);
			timeoutId.current = setTimeout(() => {
				if (isSwipe(event.srcElement, ['carousel', 'spinner', 'map'])) return;
				setIsShow(true);
			}, 100);
		});
		swipy.on('swiperight', () => {
			clearTimeout(timeoutId.current);
			setIsShow(false);
		});
	}, [isSwipe]);

	const onScrollRun = useCallback(() => {
		toggleVisibility();
		toggleMenuActive();
	}, [toggleVisibility, toggleMenuActive]);

	const onScroll = useCallback(() => {
		clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(() => {
			initScroll.current = false;
			onScrollRun();
		}, 100);
		if (!initScroll.current) {
			initScroll.current = true;
			onScrollRun();
		}
	}, [onScrollRun]);

	useEffect(() => {
		setMenuIsVisible((location?.pathname === "/") ? true : false);
	}, [location]);

	useEffect(() => {
		onSwipe();
		return () => {
			if (!location?.key) return;
			setIsShow(false);
		};
	}, [onSwipe, location]);

	useEffect(() => {
		setMenuShow(Boolean(windowWidth < 768));
	}, [windowWidth]);

	useEffect(() => {
		setNotifications(context?.user?.notifications?.filter((item) => !item.viewed));
		setNodes(context?.sections);
		setUser(context?.user);
		setMenu(context?.menu);
	}, [context]);

	useEffect(() => {
		onScroll();
		if (!navbarRef?.current) return;
		navbarRef.current.style.transition = "background-color .2s ease-out";
		window.addEventListener("scroll", onScroll);
		return () => {
			onScrollRun();
			window.removeEventListener("scroll", onScroll);
		};
	}, [onScroll, onScrollRun]);

	return (
		<Navbar ref={navbarRef} expand="md" fixed="top" className="bg-primary shadow-lg" variant="dark">
			<Container fluid="md" className="p-0">
				<Navbar.Brand className='p-0'>
					{menuIsVisible ? (
						<Fragment>
							<img id="menu-section-navbar-logo" onClick={scrollTo} src={logo} style={{ maxWidth: 150 }} className="img-fluid" alt="logo" />
							{false && (
								<span className="badge shadow fw-normal rounded-pill bg-dark fs-xs align-middle ms-2">
									status<i className={"bi bi-circle-fill ms-2 align-middle text-" + (isConnected ? "success" : "danger")} />
								</span>
							)}
						</Fragment>
					) : (
						<Link to="/">
							<img id="menu-section-navbar-logo" onClick={scrollTo} src={logo} style={{ maxWidth: 150 }} className="img-fluid" alt="logo" />
							{false && (
								<span className="badge shadow fw-normal rounded-pill bg-dark fs-xs align-middle ms-2">
									status<i className={"bi bi-circle-fill ms-2 align-middle text-" + (isConnected ? "success" : "danger")} />
								</span>
							)}
						</Link>
					)}
				</Navbar.Brand>
				<div className="d-flex align-items-center">
					{user && (
						<Link to="/user" className="text-decoration-none text-light fs-6 d-md-none">
							{user.username}
						</Link>
					)}
					<Navbar.Toggle
						onClick={toggleShow}
						className="shadow-none border-0 m">
						<div className="position-relative h-100">
							<i className="bi bi-list text-white" style={{ fontSize: 40 }} />
							{!!notifications?.length && (
								<span className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-info p-1 m-1">
									<small className="d-flex align-items-center justify-content-center fs-xs p-2 text-dark text-opacity-50" style={{ width: 12, height: 12 }}>
										{notifications.length}
									</small>
								</span>
							)}
						</div>
					</Navbar.Toggle>
				</div>

				<Navbar.Offcanvas
					show={isShow}
					scroll={false}
					placement="end"
					responsive="md"
					onHide={toggleShow}
					className="bg-primary w-100 offcanvas-menu">
					<Offcanvas.Header closeButton={false} className="border-secondary border-bottom border-opacity-25">
						<Link to="/" className='p-0 me-0 me-lg-2'>
							<img id="menu-section-offcanvas-logo" onClick={scrollTo} src={offcanvasLogo} style={{ maxWidth: 40 }} className="img-fluid" alt="logo" />
						</Link>
						<CloseButton variant="white" size="sm" className='shadow-none border-0' onClick={toggleShow} />
					</Offcanvas.Header>
					<Offcanvas.Body className="align-items-center justify-content-end ms-0">
						<Nav ref={navbarNavRef} className="justify-content-between align-items-md-center flex-grow-1 me-md-4 mb-5 mb-md-0 offcanvas-nav">
							{menu && menuIsVisible && (
								<Fragment>
									{menu.map((item, index) => (
										<Nav.Link ref={(ref) => activeSection === ref?.id.replace("menu-", "") ? ref.classList.add("active") : ref?.classList.remove("active")} id={"menu-section-" + index} key={index} onClick={scrollTo} className="d-flex text-nowrap text-light p-2">
											<span className="text-truncate d-block">{item.name}</span>
										</Nav.Link>
									))}
									<Nav.Link ref={(ref) => activeSection === ref?.id.replace("menu-", "") ? ref.classList.add("active") : null} id="menu-section-contact" onClick={scrollTo} className="d-flex text-nowrap text-light p-2">
										<span className="text-truncate d-block">Contacto</span>
									</Nav.Link>
								</Fragment>
							)}
						</Nav>

						<Nav className="justify-content-between align-items-center">
							{user ? (
								<Dropdown as={NavItem} onToggle={onDropdownToggle} show={menuShow} autoClose={(windowWidth >= 768) ? "manual" : false} align="end" className="w-100" size="lg">
									<Dropdown.Toggle as={NavLink} className="d-flex align-items-center">
										<Image style={{ maxWidth: 30 }} src={getURL.concat(user.avatar)} alt="user-avatar" roundedCircle />
										<span className="position-relative" >
											<i className="ms-2 my-auto bi bi-three-dots-vertical text-white" style={{ fontSize: 20 }} />
											{!!notifications?.length && (
												<span className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-info p-1">
													<small className="d-flex align-items-center justify-content-center fs-xs p-1 text-dark text-opacity-50" style={{ width: 12, height: 12 }}>
														{notifications.length}
													</small>
												</span>
											)}
										</span>
									</Dropdown.Toggle>
									<Dropdown.Menu className="bg-blue-400 border-opacity-50 mt-2 mt-md-0" variant="dark">
										<Dropdown.Item className="bg-transparent text-light" to="/user" as={Link}>
											<i className="bi bi-person me-2" />
											{user.username}
										</Dropdown.Item>
										<Dropdown.Divider className="my-1 border-opacity-75 shadow-lg" />
										<Dropdown.Item onClick={onLogout} className="bg-black bg-opacity-25 text-light">
											<i className="bi bi-box-arrow-right text-danger me-2" />
											<span>Logout</span>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							) : (
								<Nav.Item className="w-100 justify-content-start">
									<Nav.Link to="/login" className="me-2 fs-5 p-0 align-items-center justify-content-start text-light w-100" as={Link}>
										<i className="bi bi-person me-2" />
									</Nav.Link>
								</Nav.Item>
							)}
						</Nav>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Container>
		</Navbar>
	);
}

export default Navigation;

import axios from "axios";

const PORT_HTTPS = process.env.REACT_APP_PORT_HTTPS || 443;
const PORT_HTTP = process.env.REACT_APP_PORT_HTTP || 80;
const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
// const APK_HOST = "dev-app.duckdns.org";
const APK_HOST = "192.168.1.150";

const location = window.location;
const hostname = location.hostname;
const protocol = location.protocol.concat("//");
const port = (location.protocol === 'http:') ? PORT_HTTP : PORT_HTTPS;
// const URL = port ? protocol.concat(hostname).concat(":").concat(port) : protocol.concat(hostname);
const URL = protocol.concat((BUILD_ENV === "apk") ? APK_HOST : hostname).concat(":").concat((BUILD_ENV === "apk") ? 3443 : port);

export const getURL = URL;

export const http = axios.create({
	baseURL: getURL,
	headers: {
		"Content-type": "application/json",
	},
});

const HttpService = {
	fetchData: async (params) => {
		// console.log('params: ', params);
		const { url = null, method = "get", body = {}, config = null } = params;
		// http.defaults.headers.common['Authorization'] = (config?.accessToken) ? `Bearer ${config.accessToken}` : null;
		http.defaults.withCredentials = (config?.withCredentials === false) ? false : true;
		const response = await http[method](url, body, config);
		return response.data || [];
	}
};

export default HttpService;

const colorLogs = () => {

	const colors = {
		message: '#e2e2e2',
		success: '#20c997',
		danger: '#c83341',
		warn: '#ffc225',
		info: '#00b3ff',
	};

	console.color = {};

	Object.keys(colors).forEach((key) => {
		console.color[key] = (msg, ...args) => {
			const debug = args.slice(-1)[0];
			if ((debug !== undefined) && (typeof debug === 'boolean')) {
				args.pop();
			};
			const styles = ['color:' + colors[key] + ';'];
			if (key === 'message') {
				styles.push(
					'background:#121416;',
					'line-height:30px;',
					'padding:10px;'
				);
			}
			const isDebug = (debug === true || debug === undefined);
			if (isDebug) console.log('%c' + msg, styles.join(''), ...args);
		}
	});
};

export default colorLogs;

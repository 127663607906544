import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Document from "./Document";

const About = () => {
	return (
		<Document title={"About | saincor"} className="page-about">
			<Container>
				<Row>
					<Col>
						<h1>About</h1>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices ac eros ac egestas. Suspendisse id vulputate dolor. Maecenas felis purus, efficitur et lobortis ac, maximus eget ex. Sed vel massa interdum, sollicitudin dolor et, fringilla dolor. Phasellus ac augue faucibus, dignissim tortor a, ornare sapien. Nulla facilisi. Suspendisse purus erat, aliquet quis ipsum sit amet, interdum sagittis nunc. In diam arcu, tristique sed felis at, aliquam pharetra risus. Maecenas mi velit, hendrerit sit amet diam eget, fermentum feugiat libero. Aenean fringilla eget libero non finibus. In auctor metus eu lorem auctor, in rutrum nunc dignissim. Integer varius, lacus non tincidunt ultrices, nisl nisl mollis mauris, eget porta nulla diam at odio. Nulla nec ligula eu diam iaculis tincidunt a id risus.
						</p>
						<p>
							In hac habitasse platea dictumst. Integer pulvinar tellus convallis dignissim aliquet. Cras cursus nisi a neque varius, in elementum leo interdum. Aliquam venenatis rhoncus mi, sed ullamcorper lacus mollis nec. Curabitur sed ligula eu ex rhoncus convallis sed vel nisl. Mauris venenatis ligula vitae luctus aliquam. Nam accumsan, risus et eleifend rhoncus, elit arcu mollis mauris, porttitor consectetur turpis augue eu libero. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis ut faucibus erat, in cursus ligula. Phasellus dapibus posuere eros in pretium. Curabitur ultricies, arcu at sagittis condimentum, libero lectus efficitur enim, vel efficitur diam libero venenatis neque.
						</p>
						<p>
							Sed nec eros in tortor tristique cursus sed ac turpis. Aenean aliquam porta justo eu consequat. Curabitur tincidunt euismod dui. Cras eget egestas dolor. Aliquam molestie nisi odio, eget congue lorem sagittis non. Nunc eleifend augue non nibh molestie tincidunt. Nam tempus metus et magna tincidunt porta.
						</p>
						<p>
							Ut ut varius mauris, dapibus hendrerit neque. Vivamus porttitor suscipit mattis. Sed vel nulla nec augue pellentesque sollicitudin in cursus velit. Maecenas at nunc rutrum, bibendum tellus pellentesque, dignissim nisl. Curabitur scelerisque ligula eu dui tempor feugiat. Phasellus condimentum nisi ut ornare tempor. Integer pulvinar risus sollicitudin pulvinar aliquam. Duis augue ante, auctor a velit vel, lobortis hendrerit lorem.
						</p>
						<p>
							Etiam quis auctor arcu. Cras sollicitudin arcu nec risus scelerisque finibus. Praesent tortor ante, imperdiet sit amet lorem ac, bibendum porttitor diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas consequat tincidunt dui ut iaculis. Nunc id sem ut eros consequat scelerisque non ac nisl. Aliquam erat volutpat. Nunc et nulla non ante pretium commodo.
						</p>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices ac eros ac egestas. Suspendisse id vulputate dolor. Maecenas felis purus, efficitur et lobortis ac, maximus eget ex. Sed vel massa interdum, sollicitudin dolor et, fringilla dolor. Phasellus ac augue faucibus, dignissim tortor a, ornare sapien. Nulla facilisi. Suspendisse purus erat, aliquet quis ipsum sit amet, interdum sagittis nunc. In diam arcu, tristique sed felis at, aliquam pharetra risus. Maecenas mi velit, hendrerit sit amet diam eget, fermentum feugiat libero. Aenean fringilla eget libero non finibus. In auctor metus eu lorem auctor, in rutrum nunc dignissim. Integer varius, lacus non tincidunt ultrices, nisl nisl mollis mauris, eget porta nulla diam at odio. Nulla nec ligula eu diam iaculis tincidunt a id risus.
						</p>
						<p>
							In hac habitasse platea dictumst. Integer pulvinar tellus convallis dignissim aliquet. Cras cursus nisi a neque varius, in elementum leo interdum. Aliquam venenatis rhoncus mi, sed ullamcorper lacus mollis nec. Curabitur sed ligula eu ex rhoncus convallis sed vel nisl. Mauris venenatis ligula vitae luctus aliquam. Nam accumsan, risus et eleifend rhoncus, elit arcu mollis mauris, porttitor consectetur turpis augue eu libero. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis ut faucibus erat, in cursus ligula. Phasellus dapibus posuere eros in pretium. Curabitur ultricies, arcu at sagittis condimentum, libero lectus efficitur enim, vel efficitur diam libero venenatis neque.
						</p>
						<p>
							Sed nec eros in tortor tristique cursus sed ac turpis. Aenean aliquam porta justo eu consequat. Curabitur tincidunt euismod dui. Cras eget egestas dolor. Aliquam molestie nisi odio, eget congue lorem sagittis non. Nunc eleifend augue non nibh molestie tincidunt. Nam tempus metus et magna tincidunt porta.
						</p>
						<p>
							Ut ut varius mauris, dapibus hendrerit neque. Vivamus porttitor suscipit mattis. Sed vel nulla nec augue pellentesque sollicitudin in cursus velit. Maecenas at nunc rutrum, bibendum tellus pellentesque, dignissim nisl. Curabitur scelerisque ligula eu dui tempor feugiat. Phasellus condimentum nisi ut ornare tempor. Integer pulvinar risus sollicitudin pulvinar aliquam. Duis augue ante, auctor a velit vel, lobortis hendrerit lorem.
						</p>
						<p>
							Etiam quis auctor arcu. Cras sollicitudin arcu nec risus scelerisque finibus. Praesent tortor ante, imperdiet sit amet lorem ac, bibendum porttitor diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas consequat tincidunt dui ut iaculis. Nunc id sem ut eros consequat scelerisque non ac nisl. Aliquam erat volutpat. Nunc et nulla non ante pretium commodo.
						</p>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices ac eros ac egestas. Suspendisse id vulputate dolor. Maecenas felis purus, efficitur et lobortis ac, maximus eget ex. Sed vel massa interdum, sollicitudin dolor et, fringilla dolor. Phasellus ac augue faucibus, dignissim tortor a, ornare sapien. Nulla facilisi. Suspendisse purus erat, aliquet quis ipsum sit amet, interdum sagittis nunc. In diam arcu, tristique sed felis at, aliquam pharetra risus. Maecenas mi velit, hendrerit sit amet diam eget, fermentum feugiat libero. Aenean fringilla eget libero non finibus. In auctor metus eu lorem auctor, in rutrum nunc dignissim. Integer varius, lacus non tincidunt ultrices, nisl nisl mollis mauris, eget porta nulla diam at odio. Nulla nec ligula eu diam iaculis tincidunt a id risus.
						</p>
						<p>
							In hac habitasse platea dictumst. Integer pulvinar tellus convallis dignissim aliquet. Cras cursus nisi a neque varius, in elementum leo interdum. Aliquam venenatis rhoncus mi, sed ullamcorper lacus mollis nec. Curabitur sed ligula eu ex rhoncus convallis sed vel nisl. Mauris venenatis ligula vitae luctus aliquam. Nam accumsan, risus et eleifend rhoncus, elit arcu mollis mauris, porttitor consectetur turpis augue eu libero. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis ut faucibus erat, in cursus ligula. Phasellus dapibus posuere eros in pretium. Curabitur ultricies, arcu at sagittis condimentum, libero lectus efficitur enim, vel efficitur diam libero venenatis neque.
						</p>
						<p>
							Sed nec eros in tortor tristique cursus sed ac turpis. Aenean aliquam porta justo eu consequat. Curabitur tincidunt euismod dui. Cras eget egestas dolor. Aliquam molestie nisi odio, eget congue lorem sagittis non. Nunc eleifend augue non nibh molestie tincidunt. Nam tempus metus et magna tincidunt porta.
						</p>
						<p>
							Ut ut varius mauris, dapibus hendrerit neque. Vivamus porttitor suscipit mattis. Sed vel nulla nec augue pellentesque sollicitudin in cursus velit. Maecenas at nunc rutrum, bibendum tellus pellentesque, dignissim nisl. Curabitur scelerisque ligula eu dui tempor feugiat. Phasellus condimentum nisi ut ornare tempor. Integer pulvinar risus sollicitudin pulvinar aliquam. Duis augue ante, auctor a velit vel, lobortis hendrerit lorem.
						</p>
						<p>
							Etiam quis auctor arcu. Cras sollicitudin arcu nec risus scelerisque finibus. Praesent tortor ante, imperdiet sit amet lorem ac, bibendum porttitor diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas consequat tincidunt dui ut iaculis. Nunc id sem ut eros consequat scelerisque non ac nisl. Aliquam erat volutpat. Nunc et nulla non ante pretium commodo.
						</p>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices ac eros ac egestas. Suspendisse id vulputate dolor. Maecenas felis purus, efficitur et lobortis ac, maximus eget ex. Sed vel massa interdum, sollicitudin dolor et, fringilla dolor. Phasellus ac augue faucibus, dignissim tortor a, ornare sapien. Nulla facilisi. Suspendisse purus erat, aliquet quis ipsum sit amet, interdum sagittis nunc. In diam arcu, tristique sed felis at, aliquam pharetra risus. Maecenas mi velit, hendrerit sit amet diam eget, fermentum feugiat libero. Aenean fringilla eget libero non finibus. In auctor metus eu lorem auctor, in rutrum nunc dignissim. Integer varius, lacus non tincidunt ultrices, nisl nisl mollis mauris, eget porta nulla diam at odio. Nulla nec ligula eu diam iaculis tincidunt a id risus.
						</p>
						<p>
							In hac habitasse platea dictumst. Integer pulvinar tellus convallis dignissim aliquet. Cras cursus nisi a neque varius, in elementum leo interdum. Aliquam venenatis rhoncus mi, sed ullamcorper lacus mollis nec. Curabitur sed ligula eu ex rhoncus convallis sed vel nisl. Mauris venenatis ligula vitae luctus aliquam. Nam accumsan, risus et eleifend rhoncus, elit arcu mollis mauris, porttitor consectetur turpis augue eu libero. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis ut faucibus erat, in cursus ligula. Phasellus dapibus posuere eros in pretium. Curabitur ultricies, arcu at sagittis condimentum, libero lectus efficitur enim, vel efficitur diam libero venenatis neque.
						</p>
						<p>
							Sed nec eros in tortor tristique cursus sed ac turpis. Aenean aliquam porta justo eu consequat. Curabitur tincidunt euismod dui. Cras eget egestas dolor. Aliquam molestie nisi odio, eget congue lorem sagittis non. Nunc eleifend augue non nibh molestie tincidunt. Nam tempus metus et magna tincidunt porta.
						</p>
						<p>
							Ut ut varius mauris, dapibus hendrerit neque. Vivamus porttitor suscipit mattis. Sed vel nulla nec augue pellentesque sollicitudin in cursus velit. Maecenas at nunc rutrum, bibendum tellus pellentesque, dignissim nisl. Curabitur scelerisque ligula eu dui tempor feugiat. Phasellus condimentum nisi ut ornare tempor. Integer pulvinar risus sollicitudin pulvinar aliquam. Duis augue ante, auctor a velit vel, lobortis hendrerit lorem.
						</p>
						<p>
							Etiam quis auctor arcu. Cras sollicitudin arcu nec risus scelerisque finibus. Praesent tortor ante, imperdiet sit amet lorem ac, bibendum porttitor diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas consequat tincidunt dui ut iaculis. Nunc id sem ut eros consequat scelerisque non ac nisl. Aliquam erat volutpat. Nunc et nulla non ante pretium commodo.
						</p>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices ac eros ac egestas. Suspendisse id vulputate dolor. Maecenas felis purus, efficitur et lobortis ac, maximus eget ex. Sed vel massa interdum, sollicitudin dolor et, fringilla dolor. Phasellus ac augue faucibus, dignissim tortor a, ornare sapien. Nulla facilisi. Suspendisse purus erat, aliquet quis ipsum sit amet, interdum sagittis nunc. In diam arcu, tristique sed felis at, aliquam pharetra risus. Maecenas mi velit, hendrerit sit amet diam eget, fermentum feugiat libero. Aenean fringilla eget libero non finibus. In auctor metus eu lorem auctor, in rutrum nunc dignissim. Integer varius, lacus non tincidunt ultrices, nisl nisl mollis mauris, eget porta nulla diam at odio. Nulla nec ligula eu diam iaculis tincidunt a id risus.
						</p>
						<p>
							In hac habitasse platea dictumst. Integer pulvinar tellus convallis dignissim aliquet. Cras cursus nisi a neque varius, in elementum leo interdum. Aliquam venenatis rhoncus mi, sed ullamcorper lacus mollis nec. Curabitur sed ligula eu ex rhoncus convallis sed vel nisl. Mauris venenatis ligula vitae luctus aliquam. Nam accumsan, risus et eleifend rhoncus, elit arcu mollis mauris, porttitor consectetur turpis augue eu libero. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis ut faucibus erat, in cursus ligula. Phasellus dapibus posuere eros in pretium. Curabitur ultricies, arcu at sagittis condimentum, libero lectus efficitur enim, vel efficitur diam libero venenatis neque.
						</p>
						<p>
							Sed nec eros in tortor tristique cursus sed ac turpis. Aenean aliquam porta justo eu consequat. Curabitur tincidunt euismod dui. Cras eget egestas dolor. Aliquam molestie nisi odio, eget congue lorem sagittis non. Nunc eleifend augue non nibh molestie tincidunt. Nam tempus metus et magna tincidunt porta.
						</p>
						<p>
							Ut ut varius mauris, dapibus hendrerit neque. Vivamus porttitor suscipit mattis. Sed vel nulla nec augue pellentesque sollicitudin in cursus velit. Maecenas at nunc rutrum, bibendum tellus pellentesque, dignissim nisl. Curabitur scelerisque ligula eu dui tempor feugiat. Phasellus condimentum nisi ut ornare tempor. Integer pulvinar risus sollicitudin pulvinar aliquam. Duis augue ante, auctor a velit vel, lobortis hendrerit lorem.
						</p>
						<p>
							Etiam quis auctor arcu. Cras sollicitudin arcu nec risus scelerisque finibus. Praesent tortor ante, imperdiet sit amet lorem ac, bibendum porttitor diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas consequat tincidunt dui ut iaculis. Nunc id sem ut eros consequat scelerisque non ac nisl. Aliquam erat volutpat. Nunc et nulla non ante pretium commodo.
						</p>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices ac eros ac egestas. Suspendisse id vulputate dolor. Maecenas felis purus, efficitur et lobortis ac, maximus eget ex. Sed vel massa interdum, sollicitudin dolor et, fringilla dolor. Phasellus ac augue faucibus, dignissim tortor a, ornare sapien. Nulla facilisi. Suspendisse purus erat, aliquet quis ipsum sit amet, interdum sagittis nunc. In diam arcu, tristique sed felis at, aliquam pharetra risus. Maecenas mi velit, hendrerit sit amet diam eget, fermentum feugiat libero. Aenean fringilla eget libero non finibus. In auctor metus eu lorem auctor, in rutrum nunc dignissim. Integer varius, lacus non tincidunt ultrices, nisl nisl mollis mauris, eget porta nulla diam at odio. Nulla nec ligula eu diam iaculis tincidunt a id risus.
						</p>
						<p>
							In hac habitasse platea dictumst. Integer pulvinar tellus convallis dignissim aliquet. Cras cursus nisi a neque varius, in elementum leo interdum. Aliquam venenatis rhoncus mi, sed ullamcorper lacus mollis nec. Curabitur sed ligula eu ex rhoncus convallis sed vel nisl. Mauris venenatis ligula vitae luctus aliquam. Nam accumsan, risus et eleifend rhoncus, elit arcu mollis mauris, porttitor consectetur turpis augue eu libero. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis ut faucibus erat, in cursus ligula. Phasellus dapibus posuere eros in pretium. Curabitur ultricies, arcu at sagittis condimentum, libero lectus efficitur enim, vel efficitur diam libero venenatis neque.
						</p>
						<p>
							Sed nec eros in tortor tristique cursus sed ac turpis. Aenean aliquam porta justo eu consequat. Curabitur tincidunt euismod dui. Cras eget egestas dolor. Aliquam molestie nisi odio, eget congue lorem sagittis non. Nunc eleifend augue non nibh molestie tincidunt. Nam tempus metus et magna tincidunt porta.
						</p>
						<p>
							Ut ut varius mauris, dapibus hendrerit neque. Vivamus porttitor suscipit mattis. Sed vel nulla nec augue pellentesque sollicitudin in cursus velit. Maecenas at nunc rutrum, bibendum tellus pellentesque, dignissim nisl. Curabitur scelerisque ligula eu dui tempor feugiat. Phasellus condimentum nisi ut ornare tempor. Integer pulvinar risus sollicitudin pulvinar aliquam. Duis augue ante, auctor a velit vel, lobortis hendrerit lorem.
						</p>
						<p>
							Etiam quis auctor arcu. Cras sollicitudin arcu nec risus scelerisque finibus. Praesent tortor ante, imperdiet sit amet lorem ac, bibendum porttitor diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas consequat tincidunt dui ut iaculis. Nunc id sem ut eros consequat scelerisque non ac nisl. Aliquam erat volutpat. Nunc et nulla non ante pretium commodo.
						</p>
						<Link to="/">Go to Home</Link>
					</Col>
				</Row>
			</Container>
		</Document>
	);
}

export default About;

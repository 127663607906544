import { isMobile } from 'react-device-detect';
import { getUrlParam } from './utils';
import * as eruda from 'eruda';

const param = getUrlParam('eruda')?.split(',');
const isDev = process.env.NODE_ENV === "development";
const isShow = (param?.[1] === 'show' ? true : false);
const isEruda = (param?.[0] === 'true' ? true : false);

const checkInit = () => {
	if (isDev) {
		if (param?.[0]) return isEruda;
		if (isMobile) return true;
		return false;
	} else {
		if (param?.[0]) return isEruda;
	}
	return false;
};

const erudaInit = (force = false) => {
	const isInit = force === true ? force : checkInit();
	// const isInit = true;
	// console.log("erudaInit:", isInit);
	if (!isInit) return;
	const toolBar = [
		'console',
		'elements',
		'network',
		'resources'
	]
	eruda.init({
		tool: toolBar,
		useShadowDom: false,
		autoScale: false,
		defaults: {
			displaySize: 40,
			transparency: 0.80,
			theme: "Monokai Pro",
		},
	});
	var console = eruda.get('console');
	console.config.set('asyncRender', true);
	if (isShow) eruda.show();
};

export default erudaInit;

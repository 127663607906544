import { useEffect, useState, useCallback, useContext } from 'react';
import HttpService, { getURL } from "../http-common";
import { Container } from 'react-bootstrap';
import ContentsList from "./ContentsList";
import { Context } from "../Context";

const Contents = () => {
	const { context, setIsLoading } = useContext(Context);
	const [contents, setContents] = useState(null);

	const getContents = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/contents"),
			});
			if (result) {
				if (result?.data) {
					console.log("result.data:", result.data);
					setContents(result.data);
					// setIsLoading(false);
				}
				if (result?.message) {
					console.log("result.message:", result.message);
				}
			}
		} catch (error) {
			console.error(error.message);
			setIsLoading(false);
		}
	}, [setIsLoading]);

	useEffect(() => {
		if (!context?.menu) return;
		// setIsLoading(true);
		getContents();
	}, [context, getContents]);

	return (
		<Container className="p-0">
			{contents && (
				<ContentsList data={contents} styles={{ marginBottom: 30 }} detail />
			)}
		</Container>
	);
};

export default Contents;

import { useEffect, useState, useRef, useCallback, useContext, Fragment } from 'react';
import { Container, Carousel } from 'react-bootstrap';
import HttpService, { getURL } from "../http-common";
import ContentsList from "./ContentsList";
import { Context } from "../Context";
// import GoogleMap from "./GoogleMap";
import MapView from "./MapView";

const Home = () => {
	const { context, setSections, setBanner } = useContext(Context);
	const [bannerImages, setBannerImages] = useState([]);
	const [contents, setContents] = useState(null);
	const contactRef = useRef(null);
	const bannerRef = useRef(null);

	const itemStyles = (src) => ({
		backgroundImage: 'url(' + getURL.concat(src) + ')',
		backgroundPosition: 'center',
		backgroundRepeat: 'none',
		backgroundSize: 'cover',
		// position: 'relative',
		// paddingTop: "50%",
		// bottom: 0,
		// right: 0,
		// left: 0,
		// top: 0,
	});

	const getContents = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/contents"),
			});
			if (result) {
				if (result?.data) {
					// console.log("result.data:", result.data);
					setContents(result.data);
				}
				if (result?.message) {
					console.log("Home getContents message:", result.message);
				}
			}
		} catch (error) {
			console.error(error.message);
		}
	}, []);

	useEffect(() => {
		getContents();
	}, [getContents]);

	useEffect(() => {
		if (!contents?.length) return;
		if (!contactRef?.current) return;
		setSections((state) => ({
			...state,
			"section-contact": contactRef.current
		}));
	}, [contents, setSections]);

	useEffect(() => {
		if (!context?.banners) return;
		const images = context.banners.map((banner) => JSON.parse(banner.image)[0]);
		// console.log("images:", images);
		setBannerImages(images);
	}, [context]);

	useEffect(() => {
		if (!bannerRef?.current) return;
		setBanner(bannerRef.current);
	}, [setBanner]);

	return (
		<Fragment>
			<Container fluid className="p-0 shadow-lg">
				<div ref={bannerRef} className="card text-center bg-body-tertiary rounded-0 border-0 overflow-hidden">
					{(bannerImages?.length === 1) ? (
						<div className="carousel-banner">
							<div className="position-relative">
								<div style={itemStyles(bannerImages[0])} className="carousel-banner-item card-img rounded-0" />
							</div>
						</div>
					) : (
						<Carousel variant="dark" className="carousel-banner">
							{bannerImages?.map((src, index) => (
								<Carousel.Item key={index} className="position-relative">
									<div style={itemStyles(src)} className="carousel-banner-item card-img rounded-0" />
								</Carousel.Item>
							))}
						</Carousel>
					)}
				</div>
			</Container>

			<Container fluid className="p-0">
				{(contents !== null) && (
					<Fragment>
						<ContentsList data={contents} />
						<section id="section-contact" ref={contactRef} className="bg-gray-200">
							<Container fluid="md">
								<div className="row">
									<div className="col-md-6 d-flex align-items-center order-md-last">
										<div className="sections p-4">
											<h3 className="m-0 py-3 d-flex justify-content-between align-items-center text-orange lh-1">
												<span className="d-flex align-items-baseline">
													<img className="me-3" src={getURL.concat('/icons/contact.svg')} alt="icon" style={{ width: 30 }} />
													<span className="card-header-text lh-1">
														Contacto
													</span>
												</span>
											</h3>
											<ul className="list-group list-group-flush my-4 w-100">
												<li className="list-group-item bg-transparent d-flex justify-content-start px-0" style={{ height: 60 }}>
													<i className="bi bi-geo-alt-fill me-2 text-orange-500" />
													<span>
														<p className="m-0">Antonio Rubio 1275</p>
														<p className="lead fs-xs m-0">Montevideo, Uruguay</p>
													</span>
												</li>
												<li className="list-group-item bg-transparent px-0" style={{ minHeight: 60 }}>
													<i className="bi bi-telephone-fill me-2 text-orange-500" />
													{"(02) 323 45 84/85/86"}
												</li>
												<li className="list-group-item bg-transparent px-0" style={{ height: 60 }}>
													<i className="bi bi-envelope-fill me-2 text-orange-500" />
													info@saincor.com
												</li>
											</ul>

											<div className="d-flex align-items-center">
												<i className="bi bi-clock-fill text-body text-opacity-50 me-2 fw-bold" />
												<div className="text-body text-opacity-75 fs-xs">
													lunes a viernes de 07:00 a 12:00 y de 13:00 a 18:00,
													sábados de 07:00 a 12:00.
												</div>
											</div>
										</div>
									</div>

									<div className="col-md-6 g-0 order-md-first">
										<div className="map ratio ratio-1x1 z-1 h-100">
											<MapView />
											{/* <GoogleMap /> */}
											{/* <img src={getURL.concat("/map.png")} alt="map" /> */}
										</div>
									</div>
								</div>
							</Container>
						</section>
					</Fragment>
				)}
			</Container>
		</Fragment>
	);
};

export default Home;



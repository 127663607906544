import { useRef, useCallback, useState, useEffect } from "react";
import { alertService } from '../services/AlertService';
import HttpService, { getURL } from "../http-common";
import DisplaySpinner from "./DisplaySpinner";

const PasswordReset = () => {
	const [loading, setLoading] = useState(false);
	const [params, setParams] = useState(null);
	const formElement = useRef(null);

	const handleSubmit = useCallback(async (event) => {
		try {
			setLoading(true);
			event.preventDefault();
			const form = formElement.current;
			const data = Array.from(form.elements)
				.filter((input) => input.name)
				.reduce((obj, input) => Object.assign(obj, {
					[input.name]: input.value
				}), {});
			data.token = params?.token;
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/user/password-reset/" + params?.userId),
				body: JSON.stringify(data),
				method: "post",
			});
			if (result?.data) {
				console.log("result?.data:", result?.data);
				setLoading(false);
				if (result.message) {
					alertService.success(result.message);
					console.log(result.message);
					setLoading(false);
				}
			} else {
				if (result.message) {
					setLoading(false);
					console.log(result.message);
					alertService.warn(result.message, {
						autoClose: false,
						link: {
							path: "/forgot-password",
							text: "enviar link nuevo",
						}
					});
				}
			}

		} catch (error) {
			console.error(error.message);
		}
	}, [setLoading, params]);

	const sendData = useCallback(async () => {
		try {
			setLoading(true);
			var query = "/?";
			console.log("params:", params);
			if (params?.userId) query += "&userId=" + params.userId;
			if (params?.token) query += "&token=" + params.token;
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/password-reset").concat(query),
			});
			if (result?.data) {
				console.log("result?.data:", result?.data);
				setLoading(false);
				if (result.message) {
					console.log(result.message);
					setLoading(false);
				}
			} else {
				if (result.message) {
					alertService.warn(result.message, {
						autoClose: false,
						link: {
							path: "/forgot-password",
							text: "enviar link nuevo",
						}
					});
					console.log(result.message);
					setLoading(false);
				}
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [setLoading, params]);

	useEffect(() => {
		if (params === null) {
			const query = new URLSearchParams(window.location.search);
			const token = query.get('token')
			const userId = query.get('id')
			console.log("userId:", userId);
			console.log("token:", token);
			setParams({ token, userId });
		} else {
			sendData();
		}
	}, [sendData, params]);

	return (
		<div className="container p-4">
			{loading && (<DisplaySpinner />)}
			<div className="row my-4 d-md-none">
				<div className="col-12">
					<h2 className="fs-4 d-flex justify-content-between align-items-center w-100 border-bottom border-1 border-secondary text-start m-0 py-2">
						<span className="flex-fill">
							<i className="bi bi-person me-2 fs-4"></i>
							Resetear contraseña
						</span>
					</h2>
				</div>
			</div>
			<form className="my-5 shadow border border-secondary border-opacity-10 mx-auto col-12 col-md-8 col-lg-6 p-4 rounded-2" ref={formElement} onSubmit={handleSubmit}>
				<div className="input-group ">
					<input name="password" type="password" placeholder="password" className="form-control" id="inputPassword" autoComplete="off" />
					<button type="submit" className="btn btn-success">Enviar</button>
				</div>
			</form>
		</div>
	)
}

export default PasswordReset;

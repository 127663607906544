import { useState, useEffect, useRef, useContext, useCallback } from "react";
import { alertService } from '../services/AlertService';
import HttpService, { getURL } from "../http-common";
import InputNumber from "./InputNumber";
import { Context } from "../Context";
import Uploader from "./Uploader";

const AddContent = () => {
	const { context, updateContext, setIsLoading } = useContext(Context);
	const [selectedIcon, setSelectedIcon] = useState("");
	const [isUploaded, setIsUploaded] = useState(false);
	const [options, setOptions] = useState([]);
	const [images, setImages] = useState([]);
	const [icons, setIcons] = useState([]);
	const formElement = useRef(null);

	const handleIconChange = (event) => {
    	setSelectedIcon(event.target.value);                   
	};

	const handleSubmit = useCallback(async (event) => {
		console.log("event:", event)
		event.preventDefault();
		try {
			if (!!images?.length && !isUploaded) {
				alertService.warn("upload images before send!", { autoClose: false });
			} else {
				const form = formElement.current;
				console.log("form:", form)
				const data = Array.from(form.elements)
					.filter((input) => input.name)
					.reduce((obj, input) => {
						return Object.assign(obj, { [input.name]: input.value })
					}, {});
				if (images?.length === 0) {
					const parts = images?.[0]?.split('/');
					data.userId = context?.user.id;
					if (!!parts?.length) {
						data.image = parts[parts.length - 1];
					}
				} else {
					data.image = images?.map((img, index) => {
						const parts = img?.split('/');
						img = parts[parts.length - 1];
						return img;
					})
				}
				console.log("🚀 ~ handleSubmit ~ images:", images);
				console.log("🚀 ~ handleSubmit ~ data:", data);
				// if (true) return;
				if (!!Object.keys(data)?.length) {
					const result = await HttpService.fetchData({
						url: getURL.concat("/api/contents/add"),
						body: JSON.stringify(data),
						method: "post",
					});
					if (result) {
						if (result.data) {
							updateContext();
							if (result.message) {
								console.log(result.message);
								alertService.success(result.message, {
									autoClose: false,
									link: {
										path: "/content/" + result?.data?.id,
										text: result?.data?.name
									}
								});
							}
						} else {
							if (result.message) {
								console.error(result.message);
								alertService.error(result.message, {
									autoClose: false,
								});
							}
						}
					}
				}
			}
		} catch (error) {
			alertService.error('test', error.message);
			console.error('AddContent error', error.message);
			setIsLoading(false);
		}
	}, [context, images, isUploaded, setIsLoading, updateContext]);

	const getOptions = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/contents/layout-options"),
			});
			if (result?.data) {
				setOptions(result.data);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, []);
	
	const getIcons = useCallback(async () => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/contents/icon-options"),
			});
			if (result?.data) {
				setIcons(result.data);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, []);

	useEffect(() => {
		if (selectedIcon) return;
		if (!icons?.length) return;
		setSelectedIcon(icons[0].value);
	}, [selectedIcon, icons]);
	
	useEffect(() => {
		if (!isUploaded) return;
		alertService.clear();
	}, [isUploaded]);

	useEffect(() => {
		getOptions();
		getIcons();
	}, [getOptions, getIcons]);

	return (
		<div className="container p-4">
			<div className="rpw mb-4">
				<h1 className="display-4">Add Content</h1>
				<div className="card">
					<div className="card-body">
						<h5 className="card-title">To add content</h5>
						<p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
						<form ref={formElement} onSubmit={handleSubmit} noValidate>

							<div className="mb-3">
								<label htmlFor="name" className="form-label">
									Name:
								</label>
								<div className="col-md-8">
									<input
										type="text"
										name="name"
										className="form-control"
										required
									/>
									<div className="invalid-feedback">
										Valid first name is required.
									</div>
								</div>
							</div>

							<div className="mb-3">
								<label htmlFor="text" className="form-label">
									Text:
								</label>
								<div className="col-md-8">
									<textarea
										rows={10}
										name="text"
										className="form-control d-linebreak"
									/>
									<div className="invalid-feedback">
										Valid text is required.
									</div>
								</div>
							</div>

							<div className="mb-3">
								<label htmlFor="order" className="form-label">
									Order:
								</label>
								<div className="col-md-8">
									<InputNumber
										className="form-control"
										// onChange={onHomeTeamScoreChange}
										value={''}
										name="order"
										placeholder="auto"
										type="number"
										// required
										step={1}
										min={0}
									/>
									<div className="invalid-feedback">
										Valid order is required.
									</div>
								</div>
							</div>

							<div className="mb-3">                                                                                                                                                           
								<label htmlFor="icon" className="form-label">                                                                                                                                       
									Icon:
								</label>                                                                                                                              
								<div className="col-md-8">                                                                                                                                                           
									<div className="input-group mb-3">
								        <select
								            name="icon"
								      		value={selectedIcon}
								            onChange={handleIconChange}
								            className="form-control form-select">
								            {icons?.map(icon => (
								                <option key={icon.value} value={icon.value}>
								                    {icon.label}
								                </option>
								            ))}
								        </select>
								        <span className="input-group-text p-0 border-0" style={{ width: 40 }}>
								            <img className="preview img-fluid" src={getURL.concat('/icons/' + selectedIcon + '.svg')} alt="icon" />
								        </span>
								        <div className="invalid-feedback">
								            Valid icon is required.
								        </div>
								    </div>
								</div>
							</div>
							
							<div className="mb-3">
								<label htmlFor="layout" className="form-label">
									Layout:
								</label>
								<div className="col-md-8">
									<select name="layout" className="form-control form-select">
										{options?.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									<div className="invalid-feedback">
										Valid layout is required.
									</div>
								</div>
							</div>

							<div className="mb-3">
								<label className="form-label">Images</label>
								<div className="col-md-8">
									<Uploader
										multiple={true}
										setUploaderData={setImages}
										onUploaded={setIsUploaded}
									/>
									{!!images?.length && (
										<div className="row row-cols-4 g-2 my-2 g-1">
											{images?.map((src, index) => (
												<div key={index} className="col-3 overflow-hidden">
													<img className="preview img-fluid" src={getURL.concat(src)} alt="team" />
												</div>
											))}
										</div>
									)}
								</div>
							</div>

							<button type="submit" className="btn btn-success my-2">Guardar</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
};

export default AddContent;

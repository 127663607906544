import { io } from 'socket.io-client';

const PORT_HTTPS = process.env.REACT_APP_PORT_HTTPS;
const PORT_HTTP = process.env.REACT_APP_PORT_HTTP;
const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
// const APK_HOST = "dev-app.duckdns.org";
const APK_HOST = "192.168.1.150";

const location = window.location;
const hostname = location.hostname;
const protocol = location.protocol.concat("//");
const port = (location.protocol === 'http:') ? PORT_HTTP : PORT_HTTPS;
const URL = protocol.concat((BUILD_ENV === "apk") ? APK_HOST : hostname).concat(":").concat((BUILD_ENV === "apk") ? 3443 : port);
// console.log("URL:", URL.concat('/api/ws'));

export const socket = io(URL, {
	rejectUnauthorized: false,
	withCredentials: true,
	autoConnect: true,
	// secure: true,
});

import { useState, useEffect, useCallback } from "react";
import HttpService, { getURL } from "../http-common";
import { Carousel, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const BannerDetail = () => {
	// const { setIsLoading } = useContext(Context);
	const [content, setContent] = useState(null);
	const params = useParams();

	const getDate = (data) => {
		return new Date(data).toLocaleDateString("es-UY");
	};

	const getContent = useCallback(async (id) => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/banners/").concat(id),
			});
			console.log("result:", result);

			if (result?.data) {
				// result.data.bet = result.data.bet.filter(({ points }) => points !== null);
				setContent(result.data);
				// setIsLoading(false);
			}
		} catch (error) {
			console.error(error.message);
			// setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		console.log('ContentDetail.js');
		if (content === null) {
			// setIsLoading(true);
			getContent(params.id);
		} else {
			console.log('ContentDetail.js content: ', content);
		}
	}, [content, getContent, params]);

	return (
		<div className="container p-4">
			{content && (
				<div className="row g-md-5">
					<div className="col-md-6">
						<div className="card shadow mb-5 overflow-hidden">
							<Carousel variant="dark">
								{JSON.parse(content?.image).map((src, index) => (
									<Carousel.Item key={index}>
										{console.log("src", src)}
										<Image src={getURL.concat(src)} fluid alt="slide-item" />
										{/*
										<Carousel.Caption>
											<h3>First slide label</h3>
											<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
										</Carousel.Caption>
										*/}
									</Carousel.Item>
								))}
							</Carousel>

							<div className="card-footer d-flex align-items-center justify-content-between overflow">
								<h2 className="m-0 fs-5 flex-fill">
									<span className="badge rounded-pill bg-primary bg-opacity-50 px-3 text-truncate" style={{ maxWidth: "70%" }}>
										{content.user.username}
									</span>
								</h2>
								<div>
									<small className="fs-xs d-flex">
										<i className="bi bi-calendar3 me-2" />
										{getDate(content.createdAt)}
									</small>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<h3 className="mb-3 pb-3 d-flex justify-content-between align-items-center border-bottom border-secondary-subtle">
							<span className="text-truncate" style={{ width: 200 }}>
								{content.name}
							</span>
							<strong className="d-inline-block text-info opacity-50 fs-xs">
								<i className="bi bi-calendar me-2" />
								{getDate(content.createdAt)}
							</strong>
						</h3>

						<div>
							<small className="fs-xs d-flex">
								{content.text}
							</small>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default BannerDetail;

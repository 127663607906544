import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<div className="container p-4">
			<div className="text-center">
				<h1 className="display-1 my-4 py-2">Page no found</h1>
				<h2 className="my-4 fs-4">Oops! You seem to be lost.</h2>
				<p className="fs-6">Go to: <Link to='/'>Home</Link></p>
			</div>
		</div>
	)
}
export default NotFound;

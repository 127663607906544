const animateCSS = ({ element, animation, duration = 1000, init = null, start = null, end = null }) => {
	const prefix = 'animate__';
	const elementRef = {};

	if (typeof element === 'string' && (element.startsWith('.') || element.startsWith('#'))) {
		elementRef.current = document.querySelector(element);
	} else if (typeof element === 'object' && element?.current instanceof HTMLElement) {
		elementRef.current = element?.current;
	} else if (element instanceof HTMLElement) {
		elementRef.current = element;
	} else elementRef.current = null;
	const node = elementRef?.current;
	init?.(node);

	return new Promise((resolve, reject) => {
		if (!node) reject(false);

		node.classList.add(prefix.concat("animated"), prefix.concat(animation));
		node.style.setProperty('--animate-duration', String(duration).concat('ms'));

		const handleAnimationStart = (event) => {
			event.stopPropagation();
			start?.(node, event);
		}

		const handleAnimationEnd = (event) => {
			event.stopPropagation();
			node.classList.remove(prefix.concat("animated"), prefix.concat(animation));
			node.style.setProperty('--animate-duration', null);
			resolve(true);
			end?.(node, event);
		}

		node.addEventListener('animationstart', handleAnimationStart, { once: true });
		node.addEventListener('animationend', handleAnimationEnd, { once: true });
	});

};

export default animateCSS;

import { useState, useEffect, useRef, useCallback } from "react";
import { alertService } from '../services/AlertService';
import HttpService, { getURL } from "../http-common";
import { useNavigate } from "react-router-dom";
import DisplaySpinner from "./DisplaySpinner";

const Register = () => {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const formElement = useRef(null);
	const navigate = useNavigate();
	const phoneRef = useRef(null);

	const isValidPhone = useCallback((field) => {
		const phonePattern = /^(09[1-9]\d{6}|2[0-9]{7}|[1-9][0-9]{6,7})$/;
		if (field.value) {
			if (phonePattern.test(field.value)) {
				field.setCustomValidity("");
			} else {
				field.setCustomValidity("Número de teléfono no válido");
			}
		} else {
			field.setCustomValidity(field.validationMessage)
		}
	}, []);

	const isValidUserName = useCallback((field) => {
		var usernamePattern = /^[a-zA-Z0-9._]{6,20}$/;
		if (field.value) {
			if (usernamePattern.test(field.value)) {
				field.setCustomValidity("");
			} else {
				field.setCustomValidity("minimo 6 caracteres (mayúsculas, minúsculas, números, guiones bajos y puntos)");
			}
		} else {
			field.setCustomValidity(field.validationMessage)
		}
	}, []);

	const handleSubmit = useCallback(async (event) => {
		try {
			setMessage(null);
			setLoading(true);
			event.preventDefault();
			const form = event.target;
			form.classList.add('is-submited');
			const data = Array.from(form.elements)
				.filter((input) => input.name)
				.reduce((obj, input) => Object.assign(obj, {
					[input.name]: input.value
				}), {});
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/register"),
				body: JSON.stringify(data),
				method: "post",
			});
			if (result?.data) {
				setLoading(false);
				console.log(result.data);
				navigate("/login");
				if (result?.message) {
					alertService.success(result.message);
					console.log(result.message);
				}
			} else {
				setLoading(false);
				if (result?.message) {
					alertService.error(result.message);
					console.log(result.message);
				}
			}
		} catch (error) {
			alertService.error(error.message);
			console.error(error.message);
			setLoading(false);
		}
	}, [navigate]);

	useEffect(() => {
		if (!formElement?.current) return;
		const form = formElement.current;
		form.autocomplete = "off";
		form.noValidate = true;
		for (const field of form.elements) {
			field.autocomplete = "off";
			if (!field.validity.valid) {
				field.nextSibling.innerHTML = field.validationMessage;
			}
			field.addEventListener("input", (event) => {
				if (!event.inputType) return;
				field.nextSibling.innerHTML = field.validationMessage;
				form.classList.add('was-validated')
				if (field.name === "phone") {
					isValidPhone(field);
				}
				if (field.name === "username") {
					isValidUserName(field);
				}
			});
		}
		form.addEventListener('submit', (event) => {
			console.log("form.addEventListener ~ event:", event)
			if (!form.checkValidity()) {
				event.stopPropagation();
				event.preventDefault();
			}
			form.classList.add('was-validated')
		});
	}, [isValidPhone, isValidUserName]);

	return (
		<div className="container p-4">
			{loading && (<DisplaySpinner />)}
			{message && (
				<div className="alert alert-warning">
					<span>{message}</span>
				</div>
			)}
			<div className="row my-4 d-md-none ">
				<div className="col-12">
					<h2 className="fs-4 d-flex justify-content-between align-items-center w-100 border-bottom border-1 border-secondary text-start m-0 py-2 border-opacity-50">
						<span className=" flex-fill">
							<i className="bi bi-lock me-2 fs-4"></i>
							Registrarse
						</span>
					</h2>
				</div>
			</div>

			<form className="needs-validation my-5 shadow border border-secondary border-opacity-10 mx-auto col-12 col-md-8 col-lg-6 p-4 rounded-2" ref={formElement} onSubmit={handleSubmit}>
				<div className="mb-4">
					<input name="username" placeholder="usuario" type="text" className="form-control" id="inputUsername" aria-describedby="userHelp" required />
					<div className="invalid-feedback" />
				</div>
				<div className="mb-4">
					<input name="email" placeholder="email" type="email" className="form-control" id="inputEmail" aria-describedby="emailHelp" required />
					<div className="invalid-feedback" />
				</div>
				<div className="mb-4">
					<input ref={phoneRef} name="phone" placeholder="telefono" type="text" className="form-control" id="inputPhone" aria-describedby="phoneHelp" required />
					<div className="invalid-feedback" />
				</div>
				<div className="mb-4">
					<input name="password" placeholder="contraseña" type="password" className="form-control" id="inputPassword" required />
					<div className="invalid-feedback" />
				</div>
				<button type="submit" className="btn btn-success mt-2">
					Enviar
				</button>
			</form>
		</div>
	)
}

export default Register;


import { alertService, AlertType } from '../services/AlertService';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';

const Alert = ({ id = 'default-alert', fade = true }) => {
	const [autoScroll, setAutoScroll] = useState(false);
	const [alertRef, setAlertRef] = useState(null);
	const [alerts, setAlerts] = useState([]);
	const keepAlertRef = useRef(false);
	const locationRef = useRef(null);
	const location = useLocation();

	const alertTypeClass = {
		[AlertType.Success]: 'success',
		[AlertType.Warning]: 'warning',
		[AlertType.Error]: 'danger',
		[AlertType.Info]: 'info',
	};

	const removeAlert = useCallback((alert) => {
		if (fade) {
			const alertWithFade = { ...alert, fade: true };
			setAlerts((state) => state.map((x) => (x === alert ? alertWithFade : x)));
			setTimeout(() => {
				setAlerts((state) => state.filter((x) => (x !== alertWithFade)));
			}, 200);
		} else {
			setAlerts((state) => state.filter((x) => (x !== alert)));
		}
	}, [fade]);

	const alertClassName = (alert) => {
		if (!alert) return;
		const classes = [
			'alert',
			'w-100',
			'my-4',
			'z-1',
		];
		classes.push(('alert-' + alertTypeClass[alert.type]), ('text-' + alertTypeClass[alert.type]));
		if (alert.fade) classes.push('fade');
		else classes.push('show');
		return classes.join(' ');
	}

	const iconClassName = (alert) => {
		if (!alert) return;
		const classes = [
			'me-2',
			'bi',
		];
		const iconTypeClass = {
			[AlertType.Success]: 'check-circle-fill',
			[AlertType.Warning]: 'info-circle-fill',
			[AlertType.Info]: 'info-circle-fill',
			[AlertType.Error]: 'x-circle-fill',
		};
		classes.push(('bi-' + iconTypeClass[alert.type]));
		return classes.join(' ');
	}

	useEffect(() => {
		if (!alertRef) return;
		if (!autoScroll) return;
		setTimeout(() => alertRef.scrollIntoView(false), 600);
	}, [alertRef, autoScroll]);

	useEffect(() => {
		if (keepAlertRef.current) return;
		if (locationRef?.current?.pathname === location?.pathname) return;
		locationRef.current = location;
		alertService.clear(id);
	}, [id, location]);

	useEffect(() => {
		const subscription = alertService.onAlert(id).subscribe((alert) => {
			alert.delay = (alert?.delay !== undefined) ? alert.delay : 4000;
			alert.autoClose = (alert?.autoClose !== undefined) ? alert.autoClose : true;
			alert.autoScroll = (alert?.autoScroll !== undefined) ? alert.autoScroll : true;
			alert.keepAfterRouteChange = (alert?.keepAfterRouteChange !== undefined) ? alert.keepAfterRouteChange : false;
			keepAlertRef.current = alert.keepAfterRouteChange;
			// console.log('////////////');
			// console.log('alert', alert);
			// console.log('////////////');
			if (!alert.message) {
				// console.log("alert.message:", alert);
				setAlerts((state) => {
					const filteredAlerts = state.filter((x) => x.keepAfterRouteChange);
					filteredAlerts.forEach((x) => delete x.keepAfterRouteChange);
					return filteredAlerts;
				});
			} else {
				setAlerts((state) => ([...state, alert]));

				if (alert?.autoClose) {
					setTimeout(() => removeAlert(alert), alert.delay);
				}
				if (alert.autoScroll) {
					setAutoScroll(true);
				}
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [id, removeAlert]);

	return (
		<div className="container">
			{alerts.map((alert, index) => (
				<div ref={setAlertRef} key={index} className={alertClassName(alert)} role="alert">
					<div className="d-flex justify-content-center justify-content-md-between ">
						<div className="d-flex align-self-center">
							<i className={iconClassName(alert)} />
							<span className={"me-2 align-self-baseline" + (alert.type && (" text-" + alertTypeClass[alert.type] + "-emphasis"))} dangerouslySetInnerHTML={{ __html: alert.message }}></span>
							{alert?.link && (
								<Link className='ms-2' to={alert.link.path}>
									{alert.link.text || 'Link'}
								</Link>
							)}
						</div>
						{!alert?.autoClose && (
							<button onClick={() => removeAlert(alert)} type="button" className="btn btn-link text-dark ms-4 p-0 text-decoration-none" aria-label="Close" style={{ height: 30 }}>
								<i className={"d-flex bi bi-x fs-4 fw-bold text-opacity-75 text-" + (alert.type && (" text-" + alertTypeClass[alert.type] + "-emphasis"))} />
							</button>
						)}
					</div>
				</div>
			))}
		</div>
	);
}

export { Alert };

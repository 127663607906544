import { Spinner } from 'react-bootstrap';
// import spinner from "../spinner.gif";
import '../scss/spinner.scss';

export const DisplaySpinner = ({ fixed = true }) => {
	return (
		<div className={"spinner " + (fixed ? "spinner-fixed" : "")}>
			<div className="spinner-inner">
				<Spinner className="spinner-element" animation="border" role="status" variant="primary" />
			</div>
		</div>
	)
};

export default DisplaySpinner;

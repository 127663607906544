import { useState, useEffect, useCallback } from "react";
import HttpService, { getURL } from "../http-common";
import { useParams } from 'react-router-dom';

const UserDetail = () => {
	// const { setIsLoading } = useContext(Context);
	const [user, setUser] = useState(null);
	const params = useParams();

	const getDate = (data) => {
		return new Date(data).toLocaleDateString("es-UY");
	};

	const getUser = useCallback(async (id) => {
		try {
			const result = await HttpService.fetchData({
				url: getURL.concat("/api/user/").concat(id),
			});
			console.log("result:", result);

			if (result?.data) {
				// result.data.bet = result.data.bet.filter(({ points }) => points !== null);
				setUser(result.data);
				// setIsLoading(false);
			}
		} catch (error) {
			console.error(error.message);
			// setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		console.log('UserDetail.js');
		if (user === null) {
			// setIsLoading(true);
			getUser(params.id);
		} else {
			console.log('UserDetail.js user: ', user);
		}
	}, [user, getUser, params]);

	return (
		<div className="container p-4">
			{user && (
				<div className="row g-md-5">
					<div className="col-md-6">
						<div className="card shadow mb-5 overflow-hidden">
							<img className="img-fluid card-img-top border0" src={getURL.concat(user?.avatar)} alt="user-avatar" />
							<div className="card-footer d-flex align-items-center justify-content-between overflow">
								<h2 className="m-0 fs-5 flex-fill">
									<span className="badge rounded-pill bg-primary bg-opacity-50 px-3 text-truncate" style={{ maxWidth: "70%" }}>
										{user.username}
									</span>
								</h2>
								<div>
									<small className="fs-xs d-flex">
										<i className="bi bi-calendar3 me-2" />
										{getDate(user.createdAt)}
									</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default UserDetail;

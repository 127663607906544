import { useState, useEffect, useCallback, useRef } from 'react';

const ScrollToTop = () => {
	const [isVisible, setIsVisible] = useState(false);
	const timeoutId = useRef(null);
	const buttonRef = useRef(null);

	const toggleVisibility = useCallback(() => {
		const clientHeight = document.documentElement.clientHeight;
		const scrollTop = window.scrollY || document.documentElement.scrollTop;
		// Mostrar el botón si el scroll es mayor que el alto de la pantalla
		setIsVisible(scrollTop > clientHeight);
	}, []);

	const onScroll = useCallback(() => {
		clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(() => {
			toggleVisibility();
		}, 100);
	}, [toggleVisibility]);

	const scrollToTop = () => {
		window.scrollTo({
			behavior: 'smooth',
			top: 0,
		});
	};

	useEffect(() => {
		if (!buttonRef.current) return;
		if (isVisible) {
			buttonRef.current.style.display = "block";
			setTimeout(() => buttonRef.current.classList.add("show"), 100);
		} else {
			buttonRef.current.classList.add("fade");
			buttonRef.current.classList.remove("show");
			setTimeout(() => (buttonRef.current.style.display = "none"), 100);
		}
	}, [isVisible]);

	useEffect(() => {
		window.addEventListener("scroll", onScroll);
		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, [onScroll]);

	return (
		<div ref={buttonRef} className="scroll-to-top" style={{ display: "none" }}>
			<button style={{ lineHeight: 1 }} onClick={scrollToTop} className="scroll-to-top-btn">
				<i className="bi bi-arrow-up-circle-fill d-block pulse" style={{ fontSize: "2em" }} />
			</button>
		</div>
	);
};

export default ScrollToTop;
